import React from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../constants/media';
import { TextField } from '../atoms';

const StyledFormWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledForm = styled.div`
  position: relative;
  display: inline-block;
  width: auto;
  margin: auto;

  ${mobile`
  width: 100%;
  `}

  > div {
    display: inline-block;
  }
`;

const FloatTitle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -12em;
  margin: 0;
  font-size: 13px;
  line-height: 55px;
  text-align: right;

  ${tablet`
    position: relative;
    top: unset;
    left: unset;
    bottom: unset;
    display: block !important;
    text-align: center;
  `}

  ${mobile`
    position: relative;
    top: unset;
    left: unset;
    bottom: unset;
    display: block !important;
    text-align: center;
  `}
`;

interface Props {
  initialValue: string;
  onChange: (_: string) => void;
  hasError: boolean;
}

const FormCreateGroup: React.FC<Props> = ({
  initialValue,
  onChange,
  hasError,
}: Props) => {
  return (
    <StyledFormWrap>
      <StyledForm>
        <FloatTitle>お気に入りグループ名：</FloatTitle>
        <TextField
          value={initialValue}
          onChange={event => onChange(event.target.value)}
          placeholder="（例）提案リスト1"
          hasError={hasError}
          mode="big"
        />
      </StyledForm>
    </StyledFormWrap>
  );
};
export default FormCreateGroup;
