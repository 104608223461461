const URL_REGEX = /((https?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g;

/**
 * Normalize the anchor tag body text fetched
 * from url
 *
 * @method normalizText
 *
 * @param  {String} url
 *
 * @return {String}
 */
function normalizeText(url: string) {
  return url.replace(/^\/\//, '');
}

export function addAnchorTag(str: string): string {
  return str.replace(URL_REGEX, function (match) {
    const url = match;
    const text = normalizeText(match);
    return `<a href="${url}" target="_blank">${text}</a>`;
  });
}
