import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  NotFound,
  PersonChoiceCreate,
  PersonChoiceList,
  PersonChoiceDetail,
  PersonGroupCreate,
  PersonGroupUpdate,
  Terms,
} from '../components/pages';
import { ScrollToTop } from '../components/atoms';
import { NotificationWrap } from '../components/molecules';

const MainRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      {/* 全ページ共通コンポーネントの設置 */}
      <ScrollToTop />
      <NotificationWrap />
      <Routes>
        <Route
          path="/proposals/:proposalId/agencies/:agencyId/choices/list/"
          element={<PersonChoiceList />}
        />
        <Route
          path="/proposals/:proposalId/agencies/:agencyId/choices/create/"
          element={<PersonChoiceCreate />}
        />
        <Route
          path="/proposals/:proposalId/agencies/:agencyId/choices/detail/"
          element={<PersonChoiceDetail />}
        />
        <Route
          path="/proposals/:proposalId/agencies/:agencyId/persongroups/create/"
          element={<PersonGroupCreate />}
        />
        <Route
          path="/proposals/:proposalId/agencies/:agencyId/persongroups/:personGroupId/update/"
          element={<PersonGroupUpdate />}
        />
        <Route path="/terms/" element={<Terms />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
export default MainRoutes;
