import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  min-width: 120px;
  font-size: 11px;
  line-height: 21px;
  letter-spacing: ${120 / 1000}em;
`;

interface Props {
  children: React.ReactNode;
}

const FormLabel: React.FC<Props> = ({ children }: Props) => {
  return <StyledLabel>{children}</StyledLabel>;
};

export default FormLabel;
