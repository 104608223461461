import React from 'react';
import { Person, PersonContext } from '../entities/person';

export const initialPersonsState = (): Person[] => {
  return [];
};

export const getCheckedPersons = (state: Person[]): Person[] => {
  const isNotEmpty = !!state.length;
  return isNotEmpty ? state.filter(person => person.isChecked) : [];
};

export const getCheckedPersonsCount = (state: Person[]): number => {
  const persons = getCheckedPersons(state);
  return persons.length;
};

export const Context = React.createContext<PersonContext>({
  personsState: initialPersonsState(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatchPersons: () => {},
});
