import React, { useState, useContext } from 'react';
import {
  Modal,
  TextCentered,
  ButtonGroup,
  Button,
  TitlePrimary,
  FormControl,
  FormLabel,
  TextField,
  Spacing,
} from '../atoms';
import { useControlledComponent } from '../../lib/hooks';
import { postFormFetch, PostFormType } from '../../actions';
import { getPersonGroupCreateApiPath } from '../../constants/path';
import {
  PersonsContext,
  getCheckedPersons,
  NoticeContext,
} from '../../contexts';
import { useParams } from 'react-router-dom';
import { createNotice } from '../../repositories/notice';

interface Props {
  show: boolean;
  onClose: () => void;
}

const ModalChoiceComplete: React.FC<Props> = ({ show, onClose }: Props) => {
  // 定数の定義
  const { agencyId } = useParams<{ agencyId: string }>();
  // コンテクストの利用
  const { personsState } = useContext(PersonsContext);
  const { setNotices } = useContext(NoticeContext);

  // ローカルステートの利用
  const groupName = useControlledComponent('');
  const [groupNameError, setGroupNameError] = useState(false);

  // 関数の定義
  const onRegist = async () => {
    // もしグループ名が空だったら処理を中断
    if (groupName.value === '' || groupName.value === null) {
      setGroupNameError(true);
      return;
    }

    const postData = {
      agency_slug: agencyId,
      group_name: groupName.value,
      persons: getCheckedPersons(personsState),
    };

    const postFormData: PostFormType = {
      targetPath: getPersonGroupCreateApiPath(),
      formData: postData,
    };

    const data = await postFormFetch(postFormData);

    if (data?.status === 'success') {
      onClose(); // モーダル閉じる
      groupName.setValue(''); // グループ名を空にする
      setGroupNameError(false);
    }

    const newMessage = createNotice({ text: data.message, mode: data.status });
    setNotices({
      type: 'ADD',
      payload: newMessage,
    });
  };

  return (
    <Modal show={show} onClose={onClose}>
      <TitlePrimary>モデルの選定が完了し、内容が送信されました！</TitlePrimary>
      <TextCentered>
        今回選定したモデルのパターンを
        <br />
        「お気に入りグループ」として登録しますか？
      </TextCentered>
      <Spacing padding="20px 0">
        <FormControl>
          <FormLabel>■ お気に入りグループ名</FormLabel>
          <TextField
            value={groupName.value}
            onChange={e => groupName.setValue(e.target.value)}
            placeholder="（例）パターンA"
            hasError={groupNameError}
          />
        </FormControl>
      </Spacing>
      <ButtonGroup>
        <Button label="登録しない" ghost primary onClick={onClose} />
        <Button label="この内容で登録する" primary onClick={onRegist} />
      </ButtonGroup>
    </Modal>
  );
};
export default ModalChoiceComplete;
