import React from 'react';
import styled from 'styled-components';

const StyledFormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 680px;
`;

interface Props {
  children: React.ReactNode;
}

const FromWrap: React.FC<Props> = ({ children }: Props) => (
  <StyledFormWrap>{children}</StyledFormWrap>
);

export default FromWrap;
