import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../../constants/media';

const StyledDataListHead = styled.div`
  width: 100px;
  padding: 0.5em 1em;
  margin: 0 25px 0 0;
  font-size: 11px;
  text-align: center;
  border: 1px solid ${props => props.theme.color.BLACK};

  ${mobile`
  margin: 0 0 10px;
  `}
`;

interface Props {
  children: React.ReactNode;
}

const DataListHead: React.FC<Props> = ({ children }: Props) => (
  <StyledDataListHead>{children}</StyledDataListHead>
);

export default DataListHead;
