import React from 'react';
import {
  Modal,
  TitlePrimary,
  TextCentered,
  FormControl,
  FormLabel,
  Spacing,
  ButtonGroup,
  Button,
} from '../atoms';
import {
  PersonsContext,
  NoticeContext,
  getCheckedPersons,
  getCheckedPersonsCount,
  UiContext,
} from '../../contexts';
import { Persons } from '../molecules';
import { useParams, useNavigate } from 'react-router-dom';
import { postFormFetch, PostFormType } from '../../actions';
import { getPersonChoiceCreatePath } from '../../constants/path';
import { createNotice } from '../../repositories/notice';

type Props = {
  show: boolean;
  onClose: () => void;
  groupName: string;
  apiPath: string;
};

const ModalPersonGroupConfirm: React.FC<Props> = ({
  show,
  onClose,
  groupName,
  apiPath,
}: Props) => {
  // 定数の定義
  const { agencyId, proposalId } = useParams<{
    agencyId: string;
    proposalId: string;
  }>();
  const navigate = useNavigate();
  // コンテクストの利用
  const { personsState } = React.useContext(PersonsContext);
  const { uiSettings } = React.useContext(UiContext);
  const { setNotices } = React.useContext(NoticeContext);

  // 関数の定義
  const onRegist = () => {
    const postData = {
      agency_slug: agencyId,
      group_name: groupName,
      persons: getCheckedPersons(personsState),
    };
    const postFormData: PostFormType = {
      targetPath: apiPath,
      formData: postData,
    };
    postFormFetch(postFormData)
      .then(data => {
        // 通知を追加
        setNotices({
          type: 'ADD',
          payload: createNotice({ mode: data.status, text: data.message }),
        });
        // 成功ならモーダル閉じる
        if (data.status === 'success') {
          onClose();
        }
        // ページ遷移
        navigate(
          getPersonChoiceCreatePath({
            agencyId: agencyId,
            proposalId: proposalId,
          }),
          {
            state: {
              detailModalDisable: true,
            },
          },
        );
      })
      .catch(err => {
        alert(
          'お気に入りグループの追加に失敗しました。電波状況をお確かめになってから再度お試しください。',
        );
        console.log(err);
      });
  };

  return (
    <Modal show={show} onClose={onClose}>
      <TitlePrimary>お気に入りグループ作成の確認</TitlePrimary>
      <TextCentered>
        登録グループの名称をご確認ください。
        <br />
        お間違えなければ「この内容で登録する」をクリックしてください。
      </TextCentered>
      <Spacing padding="30px 0 0">
        <FormControl>
          <FormLabel>■ お気に入りグループ名：</FormLabel>
          <TextCentered>{groupName}</TextCentered>
        </FormControl>
      </Spacing>
      <Spacing padding="0 0 30px">
        <Persons data={personsState} uiSettings={uiSettings} />
      </Spacing>
      <ButtonGroup>
        <Button ghost primary label="モデルを選択し直す" onClick={onClose} />
        <Button
          primary
          label="この内容で登録する"
          onClick={onRegist}
          disabled={!getCheckedPersonsCount(personsState)}
        />
      </ButtonGroup>
    </Modal>
  );
};
export default ModalPersonGroupConfirm;
