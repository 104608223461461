import React from 'react';
import styled from 'styled-components';

const StyledTextLink = styled.p`
  position: relative;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: ${120 / 1000}em;
  cursor: pointer;

  &::before {
    position: absolute;
    left: -1.2em;
    display: inline-block;
    width: 0;
    height: 0;
    content: '';
    border-color: transparent transparent transparent
      ${props => props.theme.color.PRIMARY};
    border-style: solid;
    border-width: 5px 0 5px 8.7px;
    transition-property: left;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }

  &::after {
    position: absolute;
    bottom: -0.5em;
    display: block;
    width: 100%;
    height: 1px;
    content: '';
    background-color: ${props => props.theme.color.BLACK};
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }

  &:hover {
    &::before {
      left: -1.8em;
    }
    &::after {
      width: 0;
    }
  }
`;

interface Props {
  text: string;
  onClick?: () => void;
}

const TextLink: React.FC<Props> = ({ text, onClick }: Props) => (
  <StyledTextLink onClick={onClick}>{text}</StyledTextLink>
);

export default TextLink;
