import React from 'react';
import styled from 'styled-components';

const StyledPageHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 75px 0 25px;
`;

const PageTitle = styled.h1`
  font-size: 18.43px;
  font-weight: normal;
  line-height: 32px;
  letter-spacing: ${120 / 1000}em;
`;

const PageSubTitle = styled.h2`
  margin: 0 0 45px;
  font-family: 'URW-DIN-SemiCond-W05-Thin', serif;
  font-size: 18px;
  text-transform: capitalize;
  text-transform: uppercase;
  letter-spacing: ${120 / 1000}em;
`;

const PageLead = styled.p`
  padding: 60px 0 40px;
  font-size: 17px;
  font-weight: bold;
  color: ${props => props.theme.color.PRIMARY};
  letter-spacing: ${120 / 1000}em;
`;

interface Props {
  title?: string;
  subtitle?: string;
  lead?: string;
}

const PageHeading: React.FC<Props> = ({ title, subtitle, lead }: Props) => (
  <StyledPageHeading>
    <PageSubTitle>{subtitle}</PageSubTitle>
    <PageTitle>{title}</PageTitle>
    {lead && <PageLead>{lead}</PageLead>}
  </StyledPageHeading>
);
export default PageHeading;
