import React from 'react';
import styled from 'styled-components';

const TextHint = styled.p`
  margin: 8px auto;
`;

const TextError = styled(TextHint)`
  font-size: 12px;
  color: red;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 1em;
  font-size: 15px;
  line-height: 1.5;
  border: 1px solid ${props => props.theme.color.GRAY};
  border-radius: 9px;
  outline: none;

  &:focus {
    border-color: ${props => props.theme.color.PRIMARY};
  }

  &:read-only {
    border: none;
    cursor: auto;
  }
`;

interface Props {
  id: string;
  name: string;
  value: string;
  placeholder: string;
  hasError: boolean;
  isTouched: boolean;
  hintText: string;
  type: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled: boolean;
  className: string;
  rows: number;
  readOnly: boolean;
}

const TextArea: React.FC<Partial<Props>> = ({
  id,
  name,
  value = '',
  placeholder,
  hasError,
  isTouched,
  hintText,
  onChange,
  disabled,
  className,
  rows = 6,
  readOnly = false,
}: Partial<Props>) => {
  const error = isTouched && hasError;

  return (
    <>
      <StyledTextArea
        rows={rows}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={className}
        readOnly={readOnly}
      />
      {hintText && <TextHint>{hintText}</TextHint>}
      {error && <TextError>{error}</TextError>}
    </>
  );
};
export default TextArea;
