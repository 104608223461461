import React from 'react';
import styled from 'styled-components';
import { PersonsContext } from '../../../contexts';
import { Person } from '../../../entities/person';
import { Checkbox } from '../../atoms';

const StyledPersonListItem = styled.div`
  display: flex;
  align-items: center;
  width: ${(1000 - 20 * 3) / 3}px;
  margin: 0 20px 20px 0;
  transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const ItemName = styled.p`
  padding: 0 0.7em 0 1em;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: ${120 / 1000}em;
  white-space: nowrap;
`;

const ItemKana = styled.span`
  font-size: 10px;
`;

interface Props {
  person: Person;
}

const PersonListItem: React.FC<Props> = ({ person }: Props) => {
  const { dispatchPersons } = React.useContext(PersonsContext);
  const handleChangeCheck = () => {
    dispatchPersons({
      type: 'TOGGLE_CHECKED_PERSONS',
      person: person,
    });
  };

  return (
    <StyledPersonListItem>
      <Checkbox checked={person.isChecked} onChange={handleChangeCheck} />
      <ItemName> {person?.name} </ItemName>
      <ItemKana> {person?.kana} </ItemKana>
    </StyledPersonListItem>
  );
};
export default PersonListItem;
