import React from 'react';
import styled from 'styled-components';

const StyledIframeWrap = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 488px;
  padding: 75% 0 0; /* padding: calc(3 / 4 * 100%) 0 0; */
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  border: none;
`;

interface Props {
  src: string | ArrayBuffer | null;
}

const Preview: React.FC<Props> = ({ src }: Props) => {
  return (
    <StyledIframeWrap>
      <StyledIframe
        src={src + '#view=FitV&zoom=100&scrollbar=0&toolbar=0&statusbar=0'}
      />
    </StyledIframeWrap>
  );
};
export default Preview;
