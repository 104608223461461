import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './app';

// lazysizes
import 'lazysizes';
// コンポーネント再ロードした時に、classをlazyloadに戻す
import 'lazysizes/plugins/attrchange/ls.attrchange';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
