import React from 'react';
import styled from 'styled-components';

const StyledIconButton = styled.button<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  vertical-align: middle;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.color.PRIMARY : theme.color.GRAY_LIGHT};
  transition-property: box-shadow, transform;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  &:active {
    transform: scale(0.9);
  }

  &:hover {
    box-shadow: 0 12px 10px -6px rgba(0, 0, 0, 0.3);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

interface Props {
  children: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
}

const IconButton: React.FC<Props> = ({
  children,
  isActive,
  onClick,
}: Props) => (
  <StyledIconButton isActive={isActive} onClick={onClick}>
    {children}
  </StyledIconButton>
);

export default IconButton;
