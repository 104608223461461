import React, { useEffect, useContext, useState } from 'react';
import {
  Information,
  TextLink,
  TextCentered,
  Button,
  ButtonGroup,
  FormWrap,
  FormControl,
  FormLabel,
  TextArea,
  FileField,
  Paragraph,
} from '../atoms';
import {
  Persons,
  FixedHeader,
  PageOutline,
  SortArea,
  PageFooter,
  PageContent,
  Loading,
  Banner,
} from '../molecules';
import {
  ModalChoiceComplete,
  ModalProposalDetail,
  ModalPersonGroupApply,
  FetchedPageHeading,
  TermsForm,
} from '../organisms';
import {
  UiContext,
  PersonsContext,
  getCheckedPersonsCount,
  NoticeContext,
  getCheckedPersons,
} from '../../contexts';
import {
  getPersonChoiceDetailPath,
  getChoiceCreateApiPath,
  getProposalDetailApiPath,
  getPersonsListApiPath,
  getChoicesSearchApiPath,
  getPersonChoiceListPath,
} from '../../constants/path';
import {
  useControlledComponent,
  useControlledFileInput,
} from '../../lib/hooks';
import {
  getJsonData,
  scrollToTop,
  postFormFetch,
  PostFormType,
} from '../../actions';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { createNotice } from '../../repositories/notice';
import { Proposal } from '../../entities/proposal';
import { Choice } from '../../entities/choice';
import { CommonLayout } from '../templates';

const PersonChoiceCreate: React.FC = () => {
  // 定数の定義
  const { agencyId, proposalId } = useParams<{
    agencyId: string;
    proposalId: string;
  }>();
  const navigate = useNavigate();
  const personChoiceDetailPath = getPersonChoiceDetailPath({
    agencyId: agencyId,
    proposalId: proposalId,
  });
  const historyState = useLocation().state;
  const detailModalDisable = historyState?.detailModalDisable ? true : false;

  // コンテクストの利用
  const { uiSettings, dispatchUiSettings } = useContext(UiContext);
  const { personsState, dispatchPersons } = useContext(PersonsContext);
  const { setNotices } = useContext(NoticeContext);

  // ローカルステートの利用
  const [isLoadingState, setLoadingState] = useState(true);
  const [proposalDetailModalShow, setProposalDetailModal] = useState(
    !detailModalDisable,
  );
  const [choiceCompleteModalShow, setChoiceCompleteModal] = useState(false);
  const message = useControlledComponent('');
  const { onChange, currentFile } = useControlledFileInput(null);
  const [proposalState, setProposalState] = useState<Proposal>();
  const [choiceState, setChoiceState] = useState<Choice>();

  // 関数の定義
  const handleGoToConfirm = () => {
    dispatchUiSettings({
      type: 'CHANGE_MODE',
      mode: 'CONFIRM',
    });
    scrollToTop();
  };

  const handleClickOutline = () => {
    window.open(
      personChoiceDetailPath,
      'NO TITLE',
      'top=0,left=0,width=830,height=600',
    );
  };

  const onRegist = () => {
    const targetPath = getChoiceCreateApiPath({ agencyId: agencyId });
    // 送信データの作成
    const postData = new FormData();
    postData.append('proposal_id', proposalId);
    postData.append('message', message.value);
    postData.append('persons', JSON.stringify(getCheckedPersons(personsState)));
    postData.append('attachment', currentFile);

    const postFormData: PostFormType = {
      targetPath: targetPath,
      formData: postData,
      json: false,
    };

    postFormFetch(postFormData)
      .then(data => {
        if (data.status === 'success') {
          // API送信結果が成功だったらお気に入り追加選択モーダルを開く
          setChoiceCompleteModal(!choiceCompleteModalShow);

          // 選定完了モードにする
          dispatchUiSettings({
            type: 'CHANGE_MODE',
            mode: 'COMPLETED',
          });
        } else {
          setNotices({
            type: 'ADD',
            payload: createNotice({ mode: data.status, text: data.message }),
          });
        }
      })
      .catch(() => {
        alert(
          'モデルの選定に失敗しました。電波状況をお確かめになってから再度お試しください。',
        );
      });
  };

  // 初回レンダリング時にUI設定を初期化
  useEffect(() => dispatchUiSettings({ type: 'RESET' }), []);

  // ページタイトルの初期化
  useEffect(() => {
    if (proposalState?.name) {
      document.title = `モデルの選定 - ${proposalState.name}`;
    }
  }, [proposalState]);

  // モデル配列データの取得
  useEffect(() => {
    getJsonData(getPersonsListApiPath({ agencyId: agencyId }))
      .then(data => {
        // モデルの配列をローカルステートに反映
        dispatchPersons({
          type: 'SET',
          payload: data?.persons,
        });

        // 選択済みモデルのJSONを取得
        getJsonData(
          getChoicesSearchApiPath({
            agencyId: agencyId,
            proposalId: proposalId,
          }),
        )
          .then(json => {
            setChoiceState(json);
            // 選択済みモデルが存在すればチェックを反映し確認モードにする
            if ('persons' in json) {
              // お気に入りグループに属するpersonにチェックをつける
              dispatchPersons({
                type: 'APPLY_PERSON_GROUP',
                payload: json.persons,
              });
              // 確認モードにする
              dispatchUiSettings({
                type: 'CHANGE_MODE',
                mode: 'COMPLETED',
              });
              // メッセージをローカルステートに格納
              message.setValue(json.message);
            }
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  }, []);

  // 提案と撮影詳細オブジェクトの取得
  useEffect(() => {
    const targetPath = getProposalDetailApiPath({
      agencyId: agencyId,
      proposalId: proposalId,
    });
    getJsonData(targetPath)
      .then(data => {
        if (!Object.keys(data).length) {
          navigate('/page_not_found');
        }
        setProposalState(data);
        setLoadingState(false);
      })
      .catch(err => console.log(err));
  }, []);

  if (isLoadingState) {
    return <Loading />;
  } else {
    return (
      <CommonLayout>
        {/* 規約同意フォームを表示させる */}
        <TermsForm />
        <Information shooting={proposalState.shooting} />
        {uiSettings.choiceMode === 'CREATE' && (
          <FixedHeader
            count={getCheckedPersonsCount(personsState)}
            onClick={() => handleGoToConfirm()}
          />
        )}

        {/* ページコンテンツ */}
        <FetchedPageHeading />
        <PageOutline
          proposal={proposalState}
          shooting={proposalState.shooting}
          onClick={() => handleClickOutline()}
        />
        <PageContent>
          {/* 確認フェーズかどうかで切り替え */}
          {uiSettings.choiceMode === 'CREATE' && <SortArea />}
          <Persons data={personsState} uiSettings={uiSettings} />
        </PageContent>

        {/* 確認フェーズかどうかで切り替え */}
        {uiSettings.choiceMode === 'CREATE' && (
          <PageFooter>
            <TextCentered>
              モデル{getCheckedPersonsCount(personsState)}
              名が選択されています。
              <br />
              上記のモデルでよろしければ、「確認画面へ進む」ボタンを押してください。
            </TextCentered>
            <TextLink text="撮影詳細" onClick={() => handleClickOutline()} />
            <ButtonGroup>
              <Button
                label="モデル新規登録"
                onClick={() =>
                  dispatchUiSettings({ type: 'TOGGLE_MODAL_PERSON_CREATE' })
                }
              />
              <Button
                primary
                label="確認画面へ進む"
                onClick={() => handleGoToConfirm()}
                disabled={!getCheckedPersonsCount(personsState)}
              />
            </ButtonGroup>
          </PageFooter>
        )}

        {uiSettings.choiceMode === 'CONFIRM' && (
          <PageFooter>
            <FormWrap>
              <FormControl>
                <FormLabel>■ 備考／メッセージ</FormLabel>
              </FormControl>
              <FormControl>
                <TextArea
                  value={message.value}
                  onChange={e => message.setValue(e.target.value)}
                  placeholder="モデルや料金に関して補足や特記事項がございましたらご入力ください。"
                />
              </FormControl>
              <FormControl>
                <FormLabel>■ ファイル添付</FormLabel>
                <FileField type="file" onChange={e => onChange(e)} />
              </FormControl>
            </FormWrap>
            <TextCentered>
              モデル{getCheckedPersonsCount(personsState)}
              名が選択されています。
              <br />
              上記のモデルでよろしければ、「確定する」ボタンを押してください。
              <br />
              <span style={{ color: 'red' }}>
                ※ 確定ボタンを押すと、上記内容がaModel担当者へ自動送信されます。
              </span>
            </TextCentered>
            <TextCentered>
              また、コンポジットの更新が出来ない場合は「ファイル添付」にモデルの画像を添付し、
              <br />
              確定するボタンを押してください。
            </TextCentered>
            <ButtonGroup>
              <Button
                label="モデルを選び直す"
                size="small"
                onClick={() =>
                  dispatchUiSettings({
                    type: 'CHANGE_MODE',
                    mode: 'CREATE',
                  })
                }
              />
            </ButtonGroup>
            <ButtonGroup>
              <Button
                primary
                label="上記のモデルで確定する"
                size="big"
                onClick={onRegist}
                disabled={!getCheckedPersonsCount(personsState)}
              />
            </ButtonGroup>
          </PageFooter>
        )}

        {uiSettings.choiceMode === 'COMPLETED' && (
          <PageFooter>
            <FormWrap>
              <FormControl>
                <FormLabel>■ 備考／メッセージ</FormLabel>
              </FormControl>
              <FormControl>
                <TextArea
                  value={message.value}
                  onChange={e => message.setValue(e.target.value)}
                  readOnly={true}
                />
              </FormControl>
              <FormControl>
                <FormLabel>■ ファイル添付</FormLabel>
              </FormControl>
              <FormControl>
                <Paragraph>
                  <a
                    href={choiceState.attachment}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {choiceState.attachment}
                  </a>
                </Paragraph>
              </FormControl>
            </FormWrap>
            <ButtonGroup>
              <Button
                label="過去の案件一覧に戻る"
                onClick={() =>
                  navigate(
                    getPersonChoiceListPath({
                      agencyId: agencyId,
                      proposalId: proposalId,
                    }),
                  )
                }
              />
            </ButtonGroup>
          </PageFooter>
        )}

        {/* 広告エリア */}
        <Banner />

        {/* モーダル */}
        <ModalChoiceComplete
          show={choiceCompleteModalShow}
          onClose={() => setChoiceCompleteModal(!choiceCompleteModalShow)}
        />
        <ModalPersonGroupApply
          show={uiSettings.showModal.groupApply}
          onClose={() =>
            dispatchUiSettings({ type: 'TOGGLE_MODAL_GROUP_APPLY' })
          }
        />
        {/* 初回のレンダリング時のみ表示させたいモーダル */}
        <ModalProposalDetail
          show={proposalDetailModalShow}
          onClose={() => setProposalDetailModal(!proposalDetailModalShow)}
          proposal={proposalState}
          shooting={proposalState.shooting}
        />
      </CommonLayout>
    );
  }
};

export default PersonChoiceCreate;
