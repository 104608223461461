import React from 'react';
import {
  Modal,
  TextCentered,
  ButtonGroup,
  Button,
  TitlePrimary,
  FormControl,
  FormLabel,
  Spacing,
  FileField,
  Preview,
} from '../atoms';
import { useControlledFileInput } from '../../lib/hooks';
import { NoticeContext, PersonsContext } from '../../contexts';
import { postFormFetch, PostFormType } from '../../actions';
import { getUndonePersonUpdateApiPath } from '../../constants/path';
import { createNotice } from '../../repositories/notice';

type Props = {
  show: boolean;
  onClose: () => void;
  personId: string;
  name: string;
  compositePath: string;
};

const ModalPersonUpdate: React.FC<Props> = ({
  show,
  onClose,
  personId,
  name,
  compositePath,
}: Props) => {
  // コンテクストの利用
  const { dispatchPersons } = React.useContext(PersonsContext);
  const { setNotices } = React.useContext(NoticeContext);

  // ローカルステートの利用
  const [isLoading, setIsLoading] = React.useState(false);
  const { pdfData, onChange, currentFile } = useControlledFileInput(
    compositePath,
  );
  const pdfInputError = pdfData === null;

  const onRegist = () => {
    // もしグループ名が空だったら処理を中断
    if (pdfData === null) return;

    setIsLoading(true); // loading表示

    const targetPath = getUndonePersonUpdateApiPath({ personId: personId });
    const postData = new FormData();
    postData.append('composite', currentFile);
    const postFormData: PostFormType = {
      targetPath: targetPath,
      formData: postData,
      json: false,
    };

    postFormFetch(postFormData)
      .then(data => {
        setIsLoading(false); // loading解除
        setNotices({
          type: 'ADD',
          payload: createNotice({ mode: data.status, text: data.message }),
        });

        if (data.status === 'success') {
          dispatchPersons({
            type: 'UPDATE_PERSON',
            payload: data.detail,
          });
          onClose(); // モーダル閉じる
        }
      })
      .catch(err => {
        alert(
          'コンポジットの変更に失敗しました。電波状況をお確かめになってから再度お試しください。',
        );
        setIsLoading(false); // loading解除
        console.log(err);
      });
  };

  return (
    <Modal show={show} onClose={onClose}>
      <TitlePrimary>「{name}」のコンポジットを変更する</TitlePrimary>
      <TextCentered>
        「ファイルを選択」をクリックし、pdfファイルをアップロードしてください。
      </TextCentered>
      <Spacing padding="40px 0">
        <FormControl>
          <FormLabel>■ ファイル添付</FormLabel>
          <FileField
            type="file"
            accept="application/pdf"
            onChange={e => onChange(e)}
            hintText="※PDFファイルのみアップロード可能"
            hasError={pdfInputError}
          />
        </FormControl>
        {pdfData && <Preview src={pdfData} />}
      </Spacing>
      <Spacing margin="40px 0 0">
        <ButtonGroup>
          <Button label="キャンセル" ghost primary onClick={onClose} />
          <Button
            primary
            label="この内容で保存する"
            onClick={onRegist}
            isLoading={isLoading}
          />
        </ButtonGroup>
      </Spacing>
    </Modal>
  );
};
export default ModalPersonUpdate;
