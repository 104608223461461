import React, { useState } from 'react';
import styled from 'styled-components';
import { Spacing, Checkbox } from '../../atoms';
import { ModalPersonUpdate } from '../../organisms';
import { mobile, tablet, desktop } from '../../../constants/media';
import { Person } from '../../../entities/person';
import { PersonsContext } from '../../../contexts';
import { ReactComponent as UpdateIcon } from '../../../images/update-icon.svg';

interface Props {
  person: Person;
}

const StyledPersonGridItem = styled.div`
  width: 100%;
  max-width: 222px;
  margin: 0 ${(1000 - 222 * 4) / 3}px 44px 0;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  &:hover {
    transform: translateY(3px);
  }

  ${desktop`
  &:nth-child(4n) {
    margin-right: 0;
  }
  `}

  ${tablet`
  max-width: calc((100% - 20px)/2);
  margin: 0 20px 44px 0;
  &:nth-child(2n) {
    margin-right: 0;
  }
  `}

  ${mobile`
  max-width: 100%;
  margin: 0 0 44px;
  `}
`;

const ItemHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 0 1em;
  backface-visibility: hidden;
`;

const ItemTitle = styled.p`
  font-size: 15px;
  letter-spacing: ${120 / 1000}em;
`;

const ItemSubTitle = styled.span`
  font-size: 10px;
  letter-spacing: ${120 / 1000}em;
`;

const ItemContent = styled.div<{ checked: boolean }>`
  position: relative;
  z-index: 1;
  width: 100%;
  aspect-ratio: 4 / 3;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow: hidden;
  backface-visibility: hidden;

  ${({ checked, theme }) =>
    checked &&
    `
    transition: box-shadow 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    box-shadow: 0 0 0 5px ${theme.color.PRIMARY};
  `}

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`;

const ItemHoverContent = styled.div<{ isHover?: boolean; checked?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.color.BLACK_LIGHT};
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  backface-visibility: hidden;

  ${({ isHover }) =>
    isHover &&
    `
    opacity: 100;
  `};

  ${({ isHover, checked }) =>
    !isHover &&
    checked &&
    `
    opacity: 100;
    background-color: transparent;

    p { opacity: 0 }
  `};
`;

const ItemHoverText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0;
  font-size: 10px;
  color: white;
  cursor: pointer;

  svg {
    display: inline-block;
    width: 23px;
    height: 17px;
    margin: 0 0 0 1em;
    transition: transform 0.4s ease;
  }

  &:hover {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const PersonGridItem: React.FC<Props> = ({ person }: Props) => {
  const [isHover, setHover] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { dispatchPersons } = React.useContext(PersonsContext);
  const handleChangeCheck = () => {
    dispatchPersons({
      type: 'TOGGLE_CHECKED_PERSONS',
      person: person,
    });
  };

  const handleMouseToggle = () => {
    setHover(!isHover);
  };

  const toggleModalShow = () => {
    setModalShow(!modalShow);
  };

  return (
    <>
      <StyledPersonGridItem>
        <ItemHeader>
          <ItemTitle>{person?.name}</ItemTitle>
          <ItemSubTitle>{person?.kana}</ItemSubTitle>
        </ItemHeader>
        <ItemContent
          checked={person.isChecked}
          onMouseEnter={handleMouseToggle}
          onMouseLeave={handleMouseToggle}
        >
          <iframe
            data-src={person?.composite_path}
            className="lazyload"
            width="100%"
            height="100%"
          />
          <ItemHoverContent isHover={isHover} checked={person.isChecked}>
            <Spacing margin={'30px 0 0 0'}>
              <Checkbox
                checked={person.isChecked}
                onChange={handleChangeCheck}
              />
            </Spacing>
            <ItemHoverText onClick={toggleModalShow}>
              <span>コンポジット更新</span>
              <UpdateIcon />
            </ItemHoverText>
          </ItemHoverContent>
        </ItemContent>
      </StyledPersonGridItem>
      {/* コンポジット更新用のモーダル */}
      <ModalPersonUpdate
        show={modalShow}
        onClose={toggleModalShow}
        personId={person.person_id}
        name={person.name}
        compositePath={person.composite_path}
      />
    </>
  );
};
export default PersonGridItem;
