import React, { SetStateAction } from 'react';

interface Props {
  value: string;
  setValue: React.Dispatch<SetStateAction<string>>;
  hasEmptyError: boolean;
}

const useControlledComponent = (initialValue: string): Props => {
  const [valueState, setValueState] = React.useState<string>(initialValue);
  const setValue = (newValue: string) => {
    setValueState(newValue);
  };
  const hasEmptyError = valueState === '';
  return {
    value: valueState,
    setValue: setValue,
    hasEmptyError: hasEmptyError,
  };
};
export default useControlledComponent;
