import React from 'react';
import { UiContext, UiSettings } from '../entities/ui';

export const genderEnum = {
  woman: 'woman',
  man: 'man',
};

export const initialUiSettings = (): UiSettings => {
  return {
    choiceMode: 'CREATE',
    sortGender: {
      woman: true,
      man: true,
    },
    sortInitials: '',
    personListOrder: 'KANA_DSC',
    personListFormat: 'LIST',
    checksAll: false,
    drawerOpen: false,
    showModal: {
      personCreate: false,
      groupApply: false,
    },
  };
};

export const Context = React.createContext<UiContext>({
  uiSettings: initialUiSettings(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatchUiSettings: () => {},
});
