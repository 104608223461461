import { initialNoticeState } from '../contexts';
import { Notice, NoticeAction } from '../entities/notice';

export const NoticeReducer = (
  state: Notice[],
  action: NoticeAction,
): Notice[] => {
  if (action.type === 'RESET') {
    return [...initialNoticeState()];
  } else if (action.type === 'ADD' && action.payload) {
    return [...state, action.payload];
  }
  return state;
};
