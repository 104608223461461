import React from 'react';
import { PageOutline, PageOutlineMore } from '../molecules';
import { Modal, ClosingText } from '../atoms';
import { Proposal } from '../../entities/proposal';
import { Shooting } from '../../entities/shooting';

interface Props {
  proposal: Proposal;
  shooting: Shooting;
  show: boolean;
  onClose: () => void;
}

const ModalProposalDetail: React.FC<Props> = ({
  proposal,
  shooting,
  show,
  onClose,
}: Props) => {
  return (
    <Modal show={show} onClose={onClose}>
      <ClosingText shooting={shooting} />
      <PageOutline noBorder noLink proposal={proposal} shooting={shooting} />
      <PageOutlineMore shooting={shooting} />
    </Modal>
  );
};

export default ModalProposalDetail;
