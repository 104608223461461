import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { NotifyMode } from '../../../entities/notice';

const slideInFromTop = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const NotificationWrap = styled.div<{ mode: NotifyMode }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 103px;
  padding: 0 60px 0 27px;
  background-color: rgba(35, 24, 21, 0.7);
  animation: ${slideInFromTop} 1s cubic-bezier(0.165, 0.84, 0.44, 1);

  ${({ mode, theme }) =>
    mode === 'error' &&
    `
    background-color: ${theme.color.SECONDARY_LIGHT};
  `}
`;

const NotificationText = styled.span`
  font-weight: bold;
  line-height: 1.5;
  color: white;
`;

const NotificationClose = styled.div`
  position: absolute;
  top: 0;
  right: 2em;
  bottom: 0;
  width: 27px;
  height: 27px;
  margin: auto;
  cursor: pointer;

  &::before,
  &:after {
    position: absolute;
    left: 15px;
    width: 2px;
    height: 28px;
    content: ' ';
    background-color: white;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

type Prop = {
  text: string;
  mode: NotifyMode;
};

const Notification: React.FC<Prop> = ({ text, mode }) => {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <NotificationWrap mode={mode}>
        <NotificationText>{text}</NotificationText>
        <NotificationClose onClick={() => setShow(false)} />
      </NotificationWrap>
    );
  } else {
    return null;
  }
};

export default Notification;
