import React from 'react';
import styled from 'styled-components';

const CheckboxWrap = styled.label`
  display: flex;
  align-items: center;

  & + & {
    margin: 0 0 0 1em;
  }
`;

const StyledCheckboxInput = styled.input`
  display: none; /* (1) デフォルトのチェックボックスを非表示にする */

  /* (2) チェックボックスのデザイン(白の背景色にする) */
  & + span {
    position: relative;
    display: inline-block;
    width: 22px;
    height: 22px;
    background-color: white;
    border: 1px solid ${props => props.theme.color.BLACK};
    border-radius: 50%;
    transition: background-color 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }

  & + span:hover {
    background-color: ${props => props.theme.color.PRIMARY_LIGHT};
  }

  &:checked + span::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 22px;
    color: ${props => props.theme.color.PRIMARY};
    text-align: center;
    content: '●';
  }
`;

const Text = styled.p`
  margin: 0 0 0 0.5em;
  font-size: 12px;
`;

interface Props {
  checked: boolean;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
}

const CheckboxRound: React.FC<Props> = ({
  checked,
  label,
  onChange,
  name,
  value,
}: Props) => (
  <CheckboxWrap>
    <StyledCheckboxInput
      checked={checked}
      onChange={onChange}
      name={name}
      value={value}
      type="radio"
    />
    <span></span>
    {label && <Text>{label}</Text>}
  </CheckboxWrap>
);
export default CheckboxRound;
