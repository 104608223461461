// react
import React from 'react';
import { ThemeProvider } from 'styled-components';
import MainRoutes from './routes';
import { theme } from './constants/theme';
import {
  PersonsContext,
  UiContext,
  initialUiSettings,
  initialPersonsState,
  NoticeContext,
  initialNoticeState,
} from './contexts';
import { PersonsReducer, UiReducer, NoticeReducer } from './reducers';
import GlobalStyle from './styles/GlobalStyle';

const App: React.FC = () => {
  const [personsState, dispatchPersons] = React.useReducer(
    PersonsReducer,
    initialPersonsState(),
  );
  const [notices, setNotices] = React.useReducer(
    NoticeReducer,
    initialNoticeState(),
  );
  const [uiSettings, dispatchUiSettings] = React.useReducer(
    UiReducer,
    initialUiSettings(),
  );

  return (
    <PersonsContext.Provider value={{ personsState, dispatchPersons }}>
      <NoticeContext.Provider value={{ notices, setNotices }}>
        <UiContext.Provider value={{ uiSettings, dispatchUiSettings }}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <MainRoutes />
          </ThemeProvider>
        </UiContext.Provider>
      </NoticeContext.Provider>
    </PersonsContext.Provider>
  );
};
export default App;
