import { initialUiSettings } from '../contexts';
import { UiAction, UiSettings } from '../entities/ui';

export const UiReducer = (state: UiSettings, action: UiAction): UiSettings => {
  switch (action.type) {
    case 'CHANGE_MODE':
      return {
        ...state,
        choiceMode: action.mode,
      };

    case 'CHANGE_PERSONS_FORMAT':
      return {
        ...state,
        personListFormat: state.personListFormat === 'GRID' ? 'LIST' : 'GRID',
      };

    case 'CHANGE_ORDER':
      return {
        ...state,
        personListOrder: action.order,
      };

    case 'SORT_GENDER':
      return {
        ...state,
        sortGender: {
          ...state.sortGender,
          [action.gender]: !state.sortGender[action.gender],
        },
      };

    case 'SORT_INITIALS':
      return {
        ...state,
        sortInitials: action.initial,
      };

    case 'TOGGLE_CHECKED_ALL':
      return {
        ...state,
        checksAll: !state.checksAll,
      };

    case 'TOGGLE_DRAWER':
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };

    case 'TOGGLE_MODAL_PERSON_CREATE':
      return {
        ...state,
        showModal: {
          ...state.showModal,
          personCreate: !state.showModal.personCreate,
        },
      };

    case 'TOGGLE_MODAL_GROUP_APPLY':
      return {
        ...state,
        showModal: {
          ...state.showModal,
          groupApply: !state.showModal.groupApply,
        },
      };

    case 'RESET':
      return initialUiSettings();

    default:
      return state;
  }
};
