import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../../constants/media';

const DataListWrap = styled.div`
  display: flex;
  align-items: center;

  ${mobile`
  flex-direction: column;
  `}

  &+& {
    margin: 10px 0 0;
  }
`;

interface Props {
  children: React.ReactNode;
}

const DataList: React.FC<Props> = ({ children }: Props) => (
  <DataListWrap>{children}</DataListWrap>
);

export default DataList;
