import { useState } from 'react';

type FileProps = {
  pdfData: string | ArrayBuffer | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentFile: File | null;
};

const useControlledFileInput = (initialValue: string | null): FileProps => {
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(
    initialValue,
  );
  const [currentFile, setFile] = useState<File | null>(null);

  // 関数の定義
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      setFile(file);
      const reader = new FileReader();

      reader.onload = e => {
        if (e.target === null) return;
        setPdfData(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPdfData(null);
    }
  };
  return {
    pdfData,
    onChange,
    currentFile,
  };
};
export default useControlledFileInput;
