import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getJsonData, postFormFetch, PostFormType } from '../../actions';
import {
  getAgencyDetailApiPath,
  getAgencyUpdateApiPath,
} from '../../constants/path';
import { Checkbox } from '../atoms';
import sampleImg from '../../images/sample.png';
import sampleBaloonImg from '../../images/sample_baloon.png';
import { mobile, desktop, tablet } from '../../constants/media';
import { NoticeContext, PersonsContext } from '../../contexts';
import { createNotice } from '../../repositories/notice';
import { Agency } from '../../entities/agency';
import { Person } from '../../entities/person';
import { useControlledComponent } from '../../lib/hooks';

const BackgroundCover = styled.div`
  background-color: #efefef;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  animation: fadeIn 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  overflow: scroll;
`;

const Wrapper = styled.div`
  padding: 0 1em;
  overflow: hidden;
`;

const Header = styled.section`
  text-align: center;
  padding: 80px 0 45px;
  opacity: 0;
  animation: fadeInUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s forwards;
`;

const Title = styled.h1`
  font-size: 29.9635px;
  letter-spacing: 0.15em;
  line-height: 56.5106px;
  margin-bottom: 35px;
`;

const SubTitle = styled.h2`
  color: ${props => props.theme.color.PRIMARY};
  font-size: 18.9255px;
  letter-spacing: 0.135em;
  line-height: 41.5087px;
`;

const Column = styled.div`
  display: flex;
  justify-content: center;

  ${tablet`
    flex-direction: column;
    align-items: center;
  `}

  ${mobile`
    flex-direction: column;
    align-items: center;
  `}
`;

const ImageArea = styled.div`
  opacity: 0;
  animation: fadeInUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1s forwards;

  ${desktop`
    margin-right: 60px;
  `}
`;

const Form = styled.div`
  width: 100%;
  opacity: 0;
  animation: fadeInUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.5s forwards;

  ${desktop`
    max-width: 330px;
  `}

  ${tablet`
    max-width: 700px;
  `}
`;

const FormTitle = styled.h3`
  font-size: 26.8901px;
  line-height: 56.5106px;
  letter-spacing: 0.15em;
  text-align: center;
  margin: 0.5em 0 1em;
`;

const FormLabel = styled.label`
  display: block;
  color: #969797;
  font-size: 12.8605px;
  font-weight: bold;
  margin-bottom: 1em;
`;

const FormText = styled.p`
  display: block;
  font-size: 22.4474px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.15em;
  margin-bottom: 1em;
  padding: 0.5em 0;
`;

const FormInput = styled.input`
  width: 100%;
  margin-bottom: 1em;
  background-color: white;
  color: #3e3a39;
  padding: 0.7em 1em;
  font-size: 18.7062px;
  font-weight: bold;
  border: 1px solid transparent;
  outline: none;
  transition: border-color 0.3s cubic-bezier(0.23, 1, 0.32, 1);

  &:focus {
    border-color: ${props => props.theme.color.PRIMARY};
  }
`;

const FormCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin: 1em 0 2em;
`;

const FormCheckboxLabel = styled.label`
  font-size: 12.8605px;
  font-weight: bold;
  letter-spacing: 0.15em;
  margin-left: 1em;
  vertical-align: middle;
`;

const Underline = styled.a`
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: -0.5em;
    left: 0;
    height: 1px;
    background-color: #3e3a39;
    transition: width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  }

  &:hover {
    &::after {
      width: 0;
    }
  }
`;

const FormButton = styled.button`
  display: block;
  cursor: pointer;
  width: 100%;
  padding: 0.8em 2.5em;
  margin: 0 auto 1em;
  border-radius: 10px;
  background-color: ${props => props.theme.color.PRIMARY};
  color: white;
  font-size: 20.0412px;
  font-weight: bold;
  transition-property: box-shadow, transform, background-color, border-color,
    color;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  &:not(:disabled):hover {
    box-shadow: 0 12px 10px -6px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: none;
  }

  &:disabled {
    cursor: default;
    background-color: #bde0e3;
  }

  ${desktop`
    width:  auto;
  `}
`;

const Sample = styled.div`
  width: 893px;
  position: relative;
`;

const SamplePersons = styled.div`
  position: absolute;
  top: 505px;
  left: 89px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 720px;
`;

const SamplePersonItem = styled.div`
  width: 100%;
  max-width: 150px;
  opacity: 0;
  animation: fadeInUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s forwards;
`;

const SamplePersonHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 0 0.5em;
  white-space: nowrap;
`;

const SamplePersonTitle = styled.p`
  font-size: 11px;
`;

const SamplePersonSubTitle = styled.span`
  font-size: 9px;
  transform: scale(0.8);
`;

const SamplePersonContent = styled.div`
  position: relative;
  width: 100%;
  padding: 75% 0 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
  }
`;

const SampleBaloon = styled.img`
  position: absolute;
  right: -60px;
  bottom: 40px;
  z-index: 1;
  filter: drop-shadow(0 0px 3px rgba(0, 0, 0, 0.2));

  ${tablet`
    right: -20px;
  `}

  ${mobile`
    right: 180px;
    bottom: 180px;
  `}
`;

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

const TermsForm: React.FC = () => {
  const [isShow, setShow] = React.useState(false);
  const [isChecked, setChecked] = React.useState(false);
  const [agency, setAgency] = React.useState<Agency>(null);
  const { personsState } = React.useContext(PersonsContext);
  const { setNotices } = React.useContext(NoticeContext);
  const { agencyId } = useParams<{ agencyId: string }>();
  const query = useQuery();
  const email = useControlledComponent(query.get('email'));
  const tel = useControlledComponent('');

  const onChange = () => {
    setChecked(!isChecked);
  };

  const onSubmit = async () => {
    if (isChecked === false) return;

    const targetPath = getAgencyUpdateApiPath({ agencyId: agencyId });
    const formData = {
      beforeEmail: query.get('email'),
      newEmail: email.value,
      tel: tel.value,
    };
    const postFormData: PostFormType = {
      targetPath: targetPath,
      formData: formData,
    };
    const data = await postFormFetch(postFormData);

    if (data.status === 'success') {
      setShow(false);
    } else if (data.status === 'error') {
      const errorMessage = createNotice({
        text: data.message,
        mode: data.status,
      });
      setNotices({
        type: 'ADD',
        payload: errorMessage,
      });
    }
  };

  React.useEffect(() => {
    const targetPath = getAgencyDetailApiPath({ agencyId: agencyId });
    getJsonData(targetPath)
      .then(data => {
        setAgency(data);
        if (data?.agreement === false) {
          setShow(true);
          tel.setValue(data?.tel);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {isShow && (
        <BackgroundCover>
          <Wrapper>
            <Header>
              <Title>
                モデル提案のあたらしい常識。
                <br />
                スマホでタブレットで、好きな時に好きな場所でスマートに。
              </Title>
              <SubTitle>
                モデル提案システム「promodelstudio.com」で提案・選出もより簡単に行えます。
              </SubTitle>
            </Header>
            <Column>
              <ImageArea>
                <Sample>
                  <img className="lazyload" data-src={sampleImg} width="893" />
                  <SampleBaloon
                    width="226"
                    height="170"
                    className="lazyload"
                    data-src={sampleBaloonImg}
                  />
                  <SamplePersons>
                    {personsState.length
                      ? personsState.slice(0, 4).map(
                          (person: Person, index: number) =>
                            person.isVisible && (
                              <React.Fragment key={index}>
                                <SamplePersonItem>
                                  <SamplePersonHeader>
                                    <SamplePersonTitle>
                                      {person?.name}
                                    </SamplePersonTitle>
                                    <SamplePersonSubTitle>
                                      {person?.kana}
                                    </SamplePersonSubTitle>
                                  </SamplePersonHeader>
                                  <SamplePersonContent>
                                    <iframe
                                      data-src={person?.composite_path}
                                      className="lazyload"
                                    />
                                  </SamplePersonContent>
                                </SamplePersonItem>
                              </React.Fragment>
                            ),
                        )
                      : null}
                  </SamplePersons>
                </Sample>
              </ImageArea>
              <Form>
                <FormTitle>アカウント情報</FormTitle>
                <FormLabel>会社名</FormLabel>
                <FormText>{agency?.name || ''}</FormText>
                <FormLabel>モデル事務所名</FormLabel>
                <FormText>{agency?.agency_name || ''}</FormText>
                <FormLabel>メールアドレス</FormLabel>
                <FormInput
                  value={email.value || ''}
                  onChange={e => email.setValue(e.target.value)}
                />
                <FormLabel>電話番号</FormLabel>
                <FormInput
                  value={tel.value}
                  onChange={e => tel.setValue(e.target.value)}
                />
                <FormCheckbox>
                  <Checkbox checked={isChecked} onChange={onChange} />
                  <FormCheckboxLabel>
                    <Underline href="/terms/" target="_blank">
                      利用規約
                    </Underline>
                    に同意します。
                  </FormCheckboxLabel>
                </FormCheckbox>
                <FormButton disabled={!isChecked} onClick={onSubmit}>
                  登録する
                </FormButton>
              </Form>
            </Column>
          </Wrapper>
        </BackgroundCover>
      )}
    </>
  );
};
export default TermsForm;
