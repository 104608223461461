import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  letter-spacing: ${75 / 1000}em;
`;

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const TextCentered: React.FC<Props> = ({ children, style }: Props) => (
  <Text style={style}>{children}</Text>
);
export default TextCentered;
