import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { ClosingText } from '../atoms';
import { PageOutlineMore, PageOutline, Loading } from '../molecules';
import { getJsonData } from '../../actions';
import { getProposalDetailApiPath } from '../../constants/path';
import { Proposal } from '../../entities/proposal';

const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 830px;
  padding: 32px 40px;
`;

const PersonChoiceDetail: React.FC = () => {
  // 定数の定義
  const { agencyId, proposalId } = useParams<{
    agencyId: string;
    proposalId: string;
  }>();

  // ローカルステートの利用
  const [isLoading, setLoading] = React.useState(true);
  const [proposalState, setProposalState] = React.useState<Proposal>();

  // ページタイトルの初期化
  React.useEffect(() => {
    if (proposalState?.name) {
      document.title = proposalState.name;
    }
  }, [proposalState]);

  // JSON取得
  React.useEffect(() => {
    const targetPath = getProposalDetailApiPath({
      agencyId: agencyId,
      proposalId: proposalId,
    });
    getJsonData(targetPath)
      .then(data => {
        setProposalState(data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <PageWrap>
        <ClosingText shooting={proposalState.shooting} />
        <PageOutline
          noBorder
          noLink
          proposal={proposalState}
          shooting={proposalState.shooting}
        />
        <PageOutlineMore shooting={proposalState.shooting} />
      </PageWrap>
    );
  }
};
export default PersonChoiceDetail;
