import React from 'react';
import styled from 'styled-components';

interface Props {
  isExpired: boolean;
  isDone: boolean;
}

const fontFamily = "'Yu Gothic Medium','游ゴシック体',YuGothic";

const DisabledBackground = styled.div<{ brightness?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(76, 73, 72, 0.6);
  font-weight: bold;
  color: white;
  text-align: center;
  letter-spacing: 0.2em;

  ${props =>
    props.brightness === 'light' &&
    `
  background-color: rgba(255,255,255, 0.7);
  `}
`;

const DisabledText = styled.span<{ color?: string }>`
  display: inline-block;
  margin: auto;
  font-size: 18.43px;
  line-height: 2.5;
  vertical-align: middle;

  ${props =>
    props.color === 'red' &&
    `
    color: ${props.theme.color.DANGER};
  `}
`;

const BiggerText = styled.span`
  display: block;
  font-family: ${fontFamily};
  font-size: 22.68px;
`;

const PageOutlineItem: React.FC<Props> = ({ isExpired, isDone }: Props) => {
  if (isExpired === true) {
    return (
      <DisabledBackground>
        <DisabledText>提案期限終了</DisabledText>
      </DisabledBackground>
    );
  } else if (isDone === true) {
    return (
      <DisabledBackground brightness="light">
        <DisabledText color="red">
          <BiggerText>THANK YOU</BiggerText>
          モデル選定完了！ 追って進捗をご報告いたします。
        </DisabledText>
      </DisabledBackground>
    );
  }
  return null;
};
export default PageOutlineItem;
