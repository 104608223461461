import React from 'react';
import styled from 'styled-components';
import {
  DataList,
  DataListHead,
  DataListText,
  Tags,
  Tag,
  TextLink,
  PageOutlineItem,
} from '../atoms';
import { mobile } from '../../constants/media';
import { isExpiredToday } from '../../lib/utility';
import { Shooting } from '../../entities/shooting';
import { Proposal } from '../../entities/proposal';

interface Props {
  proposal: Proposal;
  shooting: Partial<Shooting>;
  choice: Record<string, unknown>;
  disabled: boolean;
  isDisable: boolean;
  onClick: () => void;
  noBorder: boolean;
  noLink: boolean;
}

const OutlineWrap = styled.div<Partial<Props>>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px 35px 50px;
  cursor: pointer;
  background-color: white;
  border: 1px solid ${props => props.theme.color.PRIMARY};
  border-radius: 9px;
  transition-property: box-shadow, transform;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  & + & {
    margin: 36px 0 0;
  }

  ${props =>
    props.isDisable &&
    `
    pointer-events: none;
    cursor: default;
  `}

  ${props =>
    props.onClick &&
    `
    &:hover {
      box-shadow: 0 12px 10px -6px rgba(0,0,0,.3);
      transform: translateY(-2px);
    }
    &:active {
      box-shadow: none;
      transform: translateY(0);
    }
  `}

  ${props =>
    props.noBorder &&
    `
    border: none;
  `}
`;

const OutlineTitle = styled.h3`
  margin: 0 0 30px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.8;
  color: ${props => props.theme.color.PRIMARY};
  letter-spacing: ${120 / 1000}em;
`;

const OutlineContent = styled.div`
  width: auto;
  max-width: 100%;
`;

const Grid = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 0;

  ${mobile`
  flex-direction: column;
  margin: 0;
  `}

  > * {
    margin: 0 37px 0 0;

    ${mobile`
    margin: 10px 0 0;
    `}
  }
`;

const GridItem = styled.div`
  display: block;
`;

const Strong = styled.span`
  padding: 0 0.2em;
  font-size: 20px;
`;

const Small = styled.span`
  font-size: 12px;
`;

const SpNewLine = styled.br`
  display: none;
  ${mobile`
  display: block;
  `}
`;

const PageOutline: React.FC<Partial<Props>> = ({
  proposal,
  shooting,
  choice,
  onClick,
  noBorder = false,
  noLink = false,
}: Partial<Props>) => {
  const isExpired =
    proposal?.expiry_date && isExpiredToday(proposal.expiry_date)
      ? true
      : false;

  const isDone = choice && Object.keys(choice).length ? true : false;

  // ページ遷移の対象は期限切れのもののみ
  // const isDisable = isExpired || isDone;
  const isDisable = isExpired;

  return (
    <OutlineWrap isDisable={isDisable} onClick={onClick} noBorder={noBorder}>
      <PageOutlineItem isExpired={isExpired} isDone={isDone} />
      <OutlineTitle>{proposal?.name}</OutlineTitle>
      <OutlineContent>
        <DataList>
          <DataListHead>広告主／商材</DataListHead>
          <DataListText>
            {shooting?.advertiser}／{shooting?.product}
          </DataListText>
        </DataList>
        <DataList>
          <DataListHead>ギャランティ</DataListHead>
          <DataListText>
            <Small>合計</Small>
            <Strong>
              {shooting?.guaranty && shooting.guaranty.toLocaleString()}
            </Strong>
            <Small>
              円
              <SpNewLine />
              &nbsp; (
              {shooting?.upper_guaranty &&
                shooting.upper_guaranty.toLocaleString()}{' '}
              円 〜{' '}
              {shooting?.lower_guaranty &&
                shooting.lower_guaranty.toLocaleString()}{' '}
              円 / 1人)
            </Small>
          </DataListText>
        </DataList>
        <Grid>
          <GridItem>
            <DataList>
              <DataListHead>採用人数</DataListHead>
              <DataListText>
                {shooting?.required_man_num ? (
                  <p>
                    男性{shooting.required_man_num}名 （
                    {shooting.required_man_age_lower}~
                    {shooting.required_man_age_upper}）
                  </p>
                ) : null}
                {shooting?.required_woman_num ? (
                  <p>
                    女性{shooting.required_woman_num}名 （
                    {shooting.required_woman_age_upper}~
                    {shooting.required_woman_age_lower}）
                  </p>
                ) : null}
                {shooting?.required_children_num ? (
                  <p>子供{shooting.required_children_num}名</p>
                ) : null}
              </DataListText>
            </DataList>
          </GridItem>
          <GridItem>
            <DataList>
              <DataListHead> 撮影日程 </DataListHead>
              <DataListText> {shooting?.schedule} </DataListText>
            </DataList>
          </GridItem>
        </Grid>
        <Grid>
          <Tags>
            {typeof shooting?.category === 'object' &&
              shooting.category.map(item => (
                <Tag key={item.name} mode={item.active ? 'active' : 'default'}>
                  {item.name}
                </Tag>
              ))}
          </Tags>
          <Tags>
            {shooting?.conflict && shooting.conflict === 'not_conflict' && (
              <>
                <Tag>競合あり</Tag>
                <Tag mode="active">競合なし</Tag>
              </>
            )}
            {shooting?.conflict && shooting.conflict === 'conflict' && (
              <>
                <Tag mode="active">競合あり</Tag>
                <Tag>競合なし</Tag>
              </>
            )}
          </Tags>
          {!noLink && <TextLink text="撮影詳細" />}
        </Grid>
      </OutlineContent>
    </OutlineWrap>
  );
};
export default PageOutline;
