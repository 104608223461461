import React from 'react';
import { PageHeading } from '../molecules';
import { getJsonData } from '../../actions';
import { getAgencyDetailApiPath } from '../../constants/path';
import { useParams } from 'react-router-dom';
import { Agency } from '../../entities/agency';

type Props = {
  lead?: string;
};

const FetchedPageHeading: React.FC<Props> = ({ lead }: Props) => {
  const { agencyId } = useParams<{ agencyId: string }>();
  const [agency, setAgency] = React.useState<Agency>();

  React.useLayoutEffect(() => {
    const targetPath = getAgencyDetailApiPath({ agencyId: agencyId });
    getJsonData(targetPath)
      .then(data => {
        setAgency(data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <PageHeading
      title={agency?.name}
      subtitle={agency?.display_name}
      lead={lead}
    />
  );
};

export default FetchedPageHeading;
