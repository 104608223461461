import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  Modal,
  TextCentered,
  ButtonGroup,
  Button,
  TitlePrimary,
  FormControl,
  Spacing,
  FormWrap,
  Checkbox,
} from '../atoms';
import { postFormFetch, PostFormType } from '../../actions';
import {
  getPersonGroupListApiPath,
  getPersonGroupCreatePath,
  getPersonGroupUpdatePath,
  getPersonGroupDeleteApiPath,
} from '../../constants/path';
import { useParams, useNavigate } from 'react-router-dom';
import { NoticeContext, PersonsContext, UiContext } from '../../contexts';
import { useControlledMultipleCheck } from '../../lib/hooks';
import { createNotice } from '../../repositories/notice';
import { ReactComponent as UpdateIcon } from '../../images/update-icon.svg';

const ItemHoverText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0;
  font-size: 10px;
  color: ${props => props.theme.color.PRIMARY};
  cursor: pointer;

  svg path {
    fill: ${props => props.theme.color.PRIMARY} !important;
  }

  &:hover {
    svg {
      transition: transform 0.4s ease;
      transform: rotate(180deg);
    }
  }
`;

const CloseIcon = styled.div`
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  &::before,
  &::after {
    position: absolute;
    left: 8px;
    width: 1px;
    height: 17px;
    content: ' ';
    background-color: ${props => props.theme.color.GRAY};
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

interface Props {
  show: boolean;
  onClose: () => void;
}

const ModalPersonGroupApply: React.FC<Props> = ({ show, onClose }: Props) => {
  // 定数の定義
  const { agencyId, proposalId } = useParams<{
    agencyId: string;
    proposalId: string;
  }>();
  const navigate = useNavigate();

  // コンテクストの利用
  const { dispatchPersons } = useContext(PersonsContext);
  const { setNotices } = useContext(NoticeContext);
  const { dispatchUiSettings } = useContext(UiContext);

  // ローカルステートの利用
  const [personGroups, setPersonGroups] = useState([]);
  const [deletedGroup, setDeletedGroup] = useState(null);
  const checked = useControlledMultipleCheck([]);

  // 関数の定義
  const handleToModalPersonGroupCreate = () => {
    onClose();
    navigate(
      getPersonGroupCreatePath({ agencyId: agencyId, proposalId: proposalId }),
    );
  };

  const onRegist = () => {
    const formData = {
      agency_slug: agencyId,
      person_group_ids: checked.items,
    };
    const postFormData: PostFormType = {
      targetPath: getPersonGroupListApiPath(),
      formData: formData,
    };
    postFormFetch(postFormData)
      .then(data => {
        // 通知を追加
        setNotices({
          type: 'ADD',
          payload: createNotice({ mode: data.status, text: data.message }),
        });
        // 成功ならモーダル閉じる
        if (data.status === 'success') {
          onClose();
          checked.reset();
          // お気に入りグループに属するpersonにチェックをつける
          dispatchPersons({
            type: 'APPLY_MULTIPLE_GROUPS',
            payload: data.detail,
          });
          // 検索条件等をリセット
          dispatchUiSettings({ type: 'RESET' });
          // チェック済み優先に並び替える
          dispatchPersons({ type: 'SORT_BY_CHECKED' });
          dispatchUiSettings({
            type: 'CHANGE_ORDER',
            order: 'iS_CHECKED',
          });
        }
      })
      .catch(err => {
        alert('お気に入りグループの追加に失敗しました: ' + err);
        console.log(err);
      });
  };

  const onDelete = async (id: string, name: string) => {
    const result = window.confirm(`「${name}」を本当に削除して宜しいですか？`);

    if (result) {
      const targetPath = getPersonGroupDeleteApiPath({ personGroupId: id });
      const postFormData: PostFormType = {
        targetPath: targetPath,
        formData: {},
      };
      await postFormFetch(postFormData)
        .then(data => {
          // データを空にする。useEffectが発動する。
          setDeletedGroup(data.detail);
          // 通知を追加
          setNotices({
            type: 'ADD',
            payload: createNotice({
              mode: data.status,
              text: data.message,
            }),
          });
        })
        .catch(error => {
          alert('お気に入りグループの削除に失敗しました。');
          console.log(error);
        });
    }
  };

  useEffect(() => {
    const postData = { agency_slug: agencyId };
    const postFormData: PostFormType = {
      targetPath: getPersonGroupListApiPath(),
      formData: postData,
    };

    postFormFetch(postFormData)
      .then(data => {
        setPersonGroups(data.detail);
      })
      .catch(() => {
        alert('お気に入りグループの一覧の取得に失敗しました。');
      });
  }, [deletedGroup]);

  return (
    <Modal show={show} onClose={onClose}>
      <TitlePrimary>お気に入りグループを選択する</TitlePrimary>
      <TextCentered>
        登録のグループを選択し「このグループを適用する」ボタンを押してください。
        <br />
        （xをクリックで削除できます。）
      </TextCentered>
      <Spacing padding="40px 0">
        <FormWrap>
          {personGroups.length > 0 ? (
            personGroups.map(item => (
              <FormControl key={item.id.toString()}>
                <Checkbox
                  value={item.id.toString()}
                  checked={checked.hasItem(item.id.toString())}
                  onChange={e => checked.toggleItem(e.target.value)}
                />
                <TextCentered>{item.name}</TextCentered>
                <ItemHoverText
                  onClick={() =>
                    navigate(
                      getPersonGroupUpdatePath({
                        agencyId: agencyId,
                        proposalId: proposalId,
                        personGroupId: item.id.toString(),
                      }),
                    )
                  }
                >
                  <span>編集</span>
                  <UpdateIcon
                    style={{
                      width: '23px',
                      height: '17px',
                      margin: '0 0 0 1em',
                    }}
                  />
                </ItemHoverText>
                <CloseIcon
                  onClick={() => onDelete(item.id.toString(), item.name)}
                />
              </FormControl>
            ))
          ) : (
            <p>お気に入りグループが未登録です。</p>
          )}
        </FormWrap>
      </Spacing>
      <Spacing margin="0 0 40px">
        <ButtonGroup>
          <Button
            ghost
            label="お気に入りグループの新規作成"
            style={{ height: '35px', fontSize: '12px' }}
            onClick={handleToModalPersonGroupCreate}
          />
        </ButtonGroup>
      </Spacing>
      <ButtonGroup>
        <Button label="キャンセル" ghost primary onClick={onClose} />
        <Button
          label="このグループを適用する"
          primary
          onClick={onRegist}
          disabled={checked.items.length === 0}
        />
      </ButtonGroup>
    </Modal>
  );
};
export default ModalPersonGroupApply;
