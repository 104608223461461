import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../../constants/media';

const TextHint = styled.p`
  margin: 8px auto;
  font-size: 12px;
`;

const TextError = styled(TextHint)`
  color: red;
`;

const StyledInput = styled.input<{ error: string }>`
  width: 310px;
  padding: 6px 8px;
  margin: 0;
  font-size: 14px;
  border: 1px solid ${props => (props.error ? 'red' : '#ddd')};
  border-radius: 3px;
  outline: none;

  &:focus {
    border-color: ${props => props.theme.color.PRIMARY};
  }

  ${mobile`
    width: 100%;
  `}
`;

interface Props {
  id: string;
  name: string;
  value: string;
  accept: string;
  placeholder: string;
  hasError: boolean;
  error: string;
  hintText: string;
  type: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  className: string;
  style: React.CSSProperties;
  ref: React.RefObject<HTMLInputElement>;
}

const FileField: React.FC<Partial<Props>> = ({
  id,
  name,
  value,
  accept,
  placeholder,
  hasError = false,
  hintText,
  type,
  onChange,
  disabled,
  className,
  style,
  ref,
}: Partial<Props>) => {
  const error = hasError ? 'ファイルが空です。' : '';

  return (
    <div className={className}>
      <StyledInput
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        accept={accept}
        disabled={disabled}
        error={error}
        style={style}
        ref={ref}
      />
      {hintText && <TextHint>{hintText}</TextHint>}
      {hasError && <TextError>{error}</TextError>}
    </div>
  );
};
export default FileField;
