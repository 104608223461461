import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../atoms';
import { addAnchorTag } from '../../lib/utility';
import { Shooting } from '../../entities/shooting';

const Divider = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 3em;
  border-bottom: 2px dotted ${props => props.theme.color.BLACK};

  &::after {
    position: absolute;
    top: -6px;
    left: 3em;
    padding: 0 0.3em;
    content: 'MORE';
    background-color: white;
  }
`;

const TextContent = styled.section`
  display: block;
  width: 100%;
  max-width: 600px;
`;

const TextRow = styled.div`
  display: flex;
`;

const TextRowHeading = styled.p`
  position: relative;
  width: 4em;
  margin: 0 2em 0 0;
  font-size: 13px;
  line-height: 21px;
  text-align-last: justify;
  text-justify: inter-ideograph;
  letter-spacing: 0;

  &::after {
    position: absolute;
    top: 0;
    right: -1em;
    content: ':';
  }
`;

const DivideText = styled.span`
  &:not(:last-child) {
    &::after {
      content: ' ／ ';
    }
  }
`;

const Subheading: FC<PropsWithChildren> = ({ children }) => (
  <Paragraph style={{ margin: '2em 0 0' }}>{children}</Paragraph>
);

interface Props {
  shooting: Shooting;
}

const PageOutlineMore: React.FC<Props> = ({ shooting }: Props) => (
  <TextContent>
    <Divider />

    {shooting?.advertiser && (
      <TextRow>
        <TextRowHeading> 商材 </TextRowHeading>
        <Paragraph>
          {shooting?.advertiser} &nbsp;
          {shooting.product_url ? (
            <a href={shooting.product_url} target="_blank" rel="noreferrer">
              {shooting.product_url}
            </a>
          ) : null}
        </Paragraph>
      </TextRow>
    )}

    {shooting?.purpose && (
      <TextRow>
        <TextRowHeading> 撮影目的 </TextRowHeading>
        <Paragraph> {shooting.purpose} </Paragraph>
      </TextRow>
    )}

    {shooting?.media_usage && (
      <TextRow>
        <TextRowHeading> 使用媒体 </TextRowHeading>
        <Paragraph> {shooting?.media_usage} </Paragraph>
      </TextRow>
    )}

    {shooting?.range_of_use && (
      <TextRow>
        <TextRowHeading> 使用範囲 </TextRowHeading>
        <Paragraph> {shooting?.range_of_use} </Paragraph>
      </TextRow>
    )}

    {shooting?.duration_of_use && (
      <TextRow>
        <TextRowHeading> 使用期間 </TextRowHeading>
        <Paragraph> {shooting?.duration_of_use} </Paragraph>
      </TextRow>
    )}

    {shooting?.duration_of_detail && (
      <TextRow>
        <TextRowHeading> 期間詳細 </TextRowHeading>
        <Paragraph> {shooting?.duration_of_detail} </Paragraph>
      </TextRow>
    )}

    <TextRow>
      <TextRowHeading> 撮影手法 </TextRowHeading>
      <Paragraph>
        {'category' in shooting &&
          shooting.category.map(item => {
            if (item.active === true) {
              return <DivideText key={item.name}>{item.name}</DivideText>;
            }
          })}
      </Paragraph>
    </TextRow>

    {shooting?.conflict && (
      <TextRow>
        <TextRowHeading> 競合 </TextRowHeading>
        <Paragraph>
          {shooting?.conflict === 'conflict' ? 'あり' : 'なし'}
        </Paragraph>
      </TextRow>
    )}

    {shooting?.range_of_conflict && (
      <TextRow>
        <TextRowHeading> 競合範囲 </TextRowHeading>
        <Paragraph> {shooting?.range_of_conflict} </Paragraph>
      </TextRow>
    )}

    {shooting?.how_to_select && (
      <TextRow>
        <TextRowHeading> 選考 </TextRowHeading>
        <Paragraph> {shooting?.how_to_select} </Paragraph>
      </TextRow>
    )}

    {shooting?.total_hours && (
      <TextRow>
        <TextRowHeading> 拘束時間 </TextRowHeading>
        <Paragraph> {shooting?.total_hours} </Paragraph>
      </TextRow>
    )}

    {shooting?.place && (
      <TextRow>
        <TextRowHeading> 撮影会場 </TextRowHeading>
        <Paragraph> {shooting?.place} </Paragraph>
      </TextRow>
    )}

    {shooting?.address && (
      <TextRow>
        <TextRowHeading> 住所 </TextRowHeading>
        <Paragraph>
          <span
            dangerouslySetInnerHTML={{
              __html: addAnchorTag(shooting.address.replace(/\n/g, '<br />')),
            }}
          />
        </Paragraph>
      </TextRow>
    )}

    {shooting?.access && (
      <TextRow>
        <TextRowHeading> アクセス </TextRowHeading>
        <Paragraph>
          <span
            dangerouslySetInnerHTML={{
              __html: addAnchorTag(shooting.access.replace(/\n/g, '<br />')),
            }}
          />
        </Paragraph>
      </TextRow>
    )}

    {shooting?.about_shooting && (
      <>
        <Subheading>= 撮影・商品について =</Subheading>
        <Paragraph>
          <span
            dangerouslySetInnerHTML={{
              __html: addAnchorTag(
                shooting.about_shooting.replace(/\n/g, '<br />'),
              ),
            }}
          />
        </Paragraph>
      </>
    )}

    {shooting?.shooting_structure && (
      <>
        <Subheading>= 撮影構成 =</Subheading>
        <Paragraph>
          <span
            dangerouslySetInnerHTML={{
              __html: addAnchorTag(
                shooting.shooting_structure.replace(/\n/g, '<br />'),
              ),
            }}
          />
        </Paragraph>
      </>
    )}

    {shooting?.model_image && (
      <>
        <Subheading>= モデルイメージ =</Subheading>
        <Paragraph>
          <span
            dangerouslySetInnerHTML={{
              __html: addAnchorTag(
                shooting.model_image.replace(/\n/g, '<br />'),
              ),
            }}
          />
        </Paragraph>
      </>
    )}

    {shooting?.audition_schedule && (
      <>
        <Subheading>= 選考スケジュールとオーディション =</Subheading>
        <Paragraph>
          <span
            dangerouslySetInnerHTML={{
              __html: addAnchorTag(
                shooting.audition_schedule.replace(/\n/g, '<br />'),
              ),
            }}
          />
        </Paragraph>
      </>
    )}

    {shooting?.notices && (
      <>
        <Subheading>= その他特記事項 =</Subheading>
        <Paragraph>
          <span
            dangerouslySetInnerHTML={{
              __html: addAnchorTag(shooting.notices.replace(/\n/g, '<br />')),
            }}
          />
        </Paragraph>
      </>
    )}
  </TextContent>
);
export default PageOutlineMore;
