import React, { useEffect } from 'react';
import { PageHeading } from '../molecules';

const NotFound: React.FC = () => {
  useEffect(() => {
    document.title = '404 NOT FOUND';
  }, []);

  return (
    <PageHeading
      title="ページが見つかりませんでした"
      subtitle="promodelstudio.com"
    />
  );
};

export default NotFound;
