import React from 'react';
import styled from 'styled-components';
import { Spacing, TextCentered } from '../../atoms';
import { PersonListItem, PersonGridItem } from '../../molecules';
import { Person } from '../../../entities/person';
import { UiSettings } from '../../../entities/ui';
import filterPersonByConditions from '../../../actions/personFilter';

const StyledPersons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface Props {
  data: Person[];
  uiSettings: UiSettings;
}

const Persons: React.FC<Props> = ({ data, uiSettings }: Props) => {
  const [persons, setPersons] = React.useState<Person[]>(data);
  const [settings, setSettings] = React.useState<UiSettings>(uiSettings);

  React.useEffect(() => {
    const persons = filterPersonByConditions(data, uiSettings);
    setPersons(persons);
    setSettings(uiSettings);
  });

  return (
    <Spacing margin={'64px 0 30px'}>
      <StyledPersons>
        {persons.length ? (
          persons.map(
            (person: Person, index: number) =>
              person.isVisible && (
                <React.Fragment key={index}>
                  {settings.personListFormat === 'LIST' && (
                    <PersonListItem person={person} />
                  )}
                  {settings.personListFormat === 'GRID' && (
                    <PersonGridItem person={person} />
                  )}
                </React.Fragment>
              ),
          )
        ) : (
          <TextCentered style={{ width: '100%', textAlign: 'center' }}>
            モデルが見つかりませんでした。
          </TextCentered>
        )}
      </StyledPersons>
    </Spacing>
  );
};

export default Persons;
