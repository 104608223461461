import React from 'react';
import { Notification } from '../atoms';
import { NoticeContext } from '../../contexts';

const NotificationWrap: React.FC = () => {
  const { notices } = React.useContext(NoticeContext);
  return (
    <>
      {notices.length > 0 &&
        notices.map((item, i) =>
          React.createElement(Notification, {
            key: i,
            text: item.text ? item.text : '',
            mode: item.mode,
          }),
        )}
    </>
  );
};
export default NotificationWrap;
