import React from 'react';
import styled from 'styled-components';

const StyledPaginationItem = styled.div<{ isCurrent?: boolean }>`
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border: 1px solid ${props => props.theme.color.BLACK};
  border-radius: 5px;
  margin: 0 0.5em 0 0;
  cursor: pointer;
  transition-property: color, background-color, box-shadow, transform;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    color: white;
    background-color: ${props => props.theme.color.BLACK};
    box-shadow: 0 12px 10px -6px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: none;
  }

  ${({ isCurrent }) =>
    isCurrent &&
    `
      border: none;
      pointer-events: none;
  `}
`;

interface Props {
  label: string;
  isCurrent: boolean;
  onClick: () => void;
}

const PaginationItem: React.FC<Props> = ({
  label,
  isCurrent,
  onClick,
}: Props) => (
  <StyledPaginationItem isCurrent={isCurrent} onClick={onClick}>
    {label}
  </StyledPaginationItem>
);
export default PaginationItem;
