import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../../constants/media';

const StyledFormControl = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 20px;

  ${mobile`
  flex-direction: column;
  align-items: flex-start;
  `}

  > * {
    &:not(:first-child) {
      margin: 0 0 0 20px;

      ${mobile`
      margin: 10px 0 0;
      `}
    }
  }
`;

interface Props {
  children: React.ReactNode;
}

const FormControl: React.FC<Props> = ({ children }: Props) => (
  <StyledFormControl>{children}</StyledFormControl>
);

export default FormControl;
