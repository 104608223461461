import styled from 'styled-components';

const StyledCloseIcon = styled.div`
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 32px;
  height: 32px;

  &:hover {
    opacity: .5;
  }

  &::before,
  &::after {
    position: absolute;
    left: 15px;
    width: 2px;
    height: 33px;
    content: ' ';
    background-color: white;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;
export default StyledCloseIcon;
