import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../../constants/media';
import { Spinner } from '..';

type ButtonSize = 'default' | 'small' | 'big';

interface Props {
  size?: ButtonSize;
  primary?: boolean;
  ghost?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  label?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const StyledButton = styled.a<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 61px;
  font-size: 16.3px;
  font-weight: bold;
  color: ${props => props.theme.color.BLACK};
  cursor: pointer;
  border: 1px solid ${props => props.theme.color.BLACK};
  border-radius: 6px;
  transition-property: box-shadow, transform, background-color, border-color,
    color;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  backface-visibility: hidden; // chromeバグフィックス 文字が1px横にずれるのを回避

  &:hover {
    box-shadow: 0 12px 10px -6px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: none;
  }

  & + & {
    margin: 0 0 0 41px;

    ${mobile`
    margin: 16px 0 0;
    `}
  }

  ${({ primary, theme }) =>
    primary &&
    `
  background-color: ${theme.color.PRIMARY};
  border-color: ${theme.color.PRIMARY};
  color: white;
  `}

  ${({ primary, ghost, theme }) =>
    primary &&
    ghost &&
    `
  background-color: white;
  border-color: ${theme.color.PRIMARY};
  color: ${theme.color.PRIMARY};
  `}

  ${({ size }) =>
    size === 'small' &&
    `
  width: 158px;
  height: 35px;
  font-size: 11.3px;
  `}

  ${({ size }) =>
    size === 'big' &&
    `
  width: 360px;
  height: 80px;
  font-size: 20px;
  `}

  ${({ disabled, isLoading, theme }) =>
    (disabled || isLoading) &&
    `
  cursor: default;
  pointer-events: none;
  background-color: ${theme.color.GRAY_LIGHT};
  border-color: ${theme.color.GRAY_LIGHT};
  `}
`;

const Button: React.FC<Props> = ({
  label,
  onClick,
  primary,
  ghost,
  size = 'default',
  style,
  disabled = false,
  isLoading = false,
}: Props) => (
  <StyledButton
    onClick={onClick}
    primary={primary}
    ghost={ghost}
    size={size}
    style={style}
    disabled={disabled}
    isLoading={isLoading}
  >
    {isLoading ? <Spinner /> : label}
  </StyledButton>
);
export default Button;
