import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DownIcon } from '../../../images/down-icon.svg';

const SelectboxWrap = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSelectbox = styled.select`
  appearance: none;
  display: inline-block;
  font-size: 12px;
  border: none;
  outline: none;
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1);

  &:active {
    border: none;
  }

  &:hover {
    opacity: 0.6;
  }
`;

interface Props {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: {
    key: string;
    value: string;
  }[];
}

const Selectbox: React.FC<Props> = ({ value, onChange, options }: Props) => {
  return (
    <SelectboxWrap>
      <DownIcon
        title="down"
        style={{
          width: '20px',
          height: '13px',
          display: 'flex',
          flexDirection: 'column',
          margin: '0 .5em 0 0',
        }}
      />
      <StyledSelectbox value={value} onChange={onChange}>
        {options.map(({ key, value }) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </StyledSelectbox>
    </SelectboxWrap>
  );
};
export default Selectbox;
