import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../../constants/media';

const StyledButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${mobile`
  flex-direction: column;
  `}
`;

interface Props {
  children: React.ReactNode;
}

const ButtonGroup: React.FC<Props> = ({ children }: Props) => (
  <StyledButtonGroup>{children}</StyledButtonGroup>
);
export default ButtonGroup;
