import React from 'react';
import styled from 'styled-components';

const StyledPaginationWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 4em 0 0;
  text-align: center;
`;

interface Props {
  children: React.ReactNode;
}

const PaginationWrap: React.FC<Props> = ({ children }: Props) => (
  <StyledPaginationWrap>{children}</StyledPaginationWrap>
);

export default PaginationWrap;
