import React from 'react';
import styled from 'styled-components';

const StyledTags = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  children: React.ReactNode;
}

const Tags: React.FC<Props> = ({ children }: Props) => (
  <StyledTags>{children}</StyledTags>
);

export default Tags;
