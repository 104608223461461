import React, { useContext } from 'react';
import styled from 'styled-components';
import { UiContext } from '../../contexts';

const StyledBurger = styled.button<{ isActive?: boolean }>`
  position: absolute;
  top: 50px;
  right: ${props => props.theme.size.padding};
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 35px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }

  &:hover {
    div:nth-child(1) {
      transform: translateY(2px);
    }
    div:nth-child(3) {
      transform: translateY(-2px);
    }
  }

  div {
    position: relative;
    width: 35px;
    height: 1px;
    background: ${({ isActive, theme }) =>
      isActive ? 'white' : theme.color.BLACK};
    border-radius: 10px;
    transition-property: background, transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    transform-origin: 1px;
  }
`;

interface Props {
  style?: React.CSSProperties;
}

const Hamburger: React.FC<Props> = ({ style }: Props) => {
  const { uiSettings, dispatchUiSettings } = useContext(UiContext);

  const handleClick = () => {
    dispatchUiSettings({ type: 'TOGGLE_DRAWER' });
  };

  return (
    <StyledBurger
      isActive={uiSettings.drawerOpen}
      onClick={handleClick}
      style={style}
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Hamburger;
