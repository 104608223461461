interface Props {
  agencyId?: string;
  proposalId?: string;
  personId?: string;
  personGroupId?: string;
  page?: number;
}

export const getPersonChoiceListPath = ({
  agencyId,
  proposalId,
}: Props): string => {
  return `/proposals/${proposalId}/agencies/${agencyId}/choices/list/`;
};

export const getPersonChoiceCreatePath = ({
  agencyId,
  proposalId,
}: Props): string => {
  return `/proposals/${proposalId}/agencies/${agencyId}/choices/create/`;
};

export const getPersonChoiceDetailPath = ({
  agencyId,
  proposalId,
}: Props): string => {
  return `/proposals/${proposalId}/agencies/${agencyId}/choices/detail/`;
};

export const getPersonGroupCreatePath = ({
  agencyId,
  proposalId,
}: Props): string => {
  return `/proposals/${proposalId}/agencies/${agencyId}/persongroups/create/`;
};

export const getPersonGroupUpdatePath = ({
  agencyId,
  proposalId,
  personGroupId,
}: Props): string => {
  return `/proposals/${proposalId}/agencies/${agencyId}/persongroups/${personGroupId}/update/`;
};

export const getPersonGroupListApiPath = (): string => {
  return '/apis/v1/persongroups/';
};

export const getPersonGroupCreateApiPath = (): string => {
  return '/apis/v1/persongroups/create/';
};

export const getPersonGroupDetailApiPath = ({
  personGroupId,
}: Props): string => {
  return `/apis/v1/persongroups/${personGroupId}/`;
};

export const getPersonGroupUpdateApiPath = ({
  personGroupId,
}: Props): string => {
  return `/apis/v1/persongroups/${personGroupId}/update/`;
};

export const getPersonGroupDeleteApiPath = ({
  personGroupId,
}: Props): string => {
  return `/apis/v1/persongroups/${personGroupId}/delete/`;
};

export const getUndonePersonCreateApiPath = (): string => {
  return '/apis/v1/undone_persons/create/';
};

export const getUndonePersonUpdateApiPath = ({ personId }: Props): string => {
  return `/apis/v1/undone_persons/${personId}/update/`;
};

export const getChoiceCreateApiPath = ({ agencyId }: Props): string => {
  return `/apis/v1/agencies/${agencyId}/choices/`;
};

export const getAgencyDetailApiPath = ({ agencyId }: Props): string => {
  return `/apis/v1/agency/${agencyId}/`;
};

export const getChoicesListApiPath = ({ agencyId, page }: Props): string => {
  const pageQuery = page ? page : '1';
  return `/apis/v1/proposals/list/?agency_slug=${agencyId}&page=${pageQuery}`;
};

export const getProposalDetailApiPath = ({
  agencyId,
  proposalId,
}: Props): string => {
  return `/apis/v1/proposals/${proposalId}/?agency_slug=${agencyId}`;
};

export const getPersonsListApiPath = ({ agencyId }: Props): string => {
  return `/apis/v1/persons/?agency_slug=${agencyId}`;
};

export const getChoicesSearchApiPath = ({
  agencyId,
  proposalId,
}: Props): string => {
  return `/apis/v1/choices/search/?agency_slug=${agencyId}&proposal_id=${proposalId}`;
};

export const getAgencyUpdateApiPath = ({ agencyId }: Props): string => {
  return `/apis/v1/agency/${agencyId}/update/`;
};
