import React from 'react';
import styled from 'styled-components';

const StyledParagraph = styled.p`
  font-size: 13px;
  line-height: 21px;
  letter-spacing: ${75 / 1000}em;
  word-break: break-word;

  a:not([class]) {
    color: ${props => props.theme.color.PRIMARY};
    text-decoration: underline;
  }
`;

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Paragraph: React.FC<Props> = ({ children, style }: Props) => (
  <StyledParagraph style={style}>{children}</StyledParagraph>
);

export default Paragraph;
