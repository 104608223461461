import React from 'react';
import styled from 'styled-components';

const StyledSmallButton = styled.button`
  display: inline-block;
  padding: 0.5em 1em 0.3em;
  font-size: 11.5px;
  text-align: center;
  cursor: pointer;
  border: 1px solid ${props => props.theme.color.BLACK};
  border-radius: 1em;
  transition-property: color, background-color, border-color, transform;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  &:hover {
    color: ${props => props.theme.color.PRIMARY};
    background-color: #f3f9fa;
    border-color: ${props => props.theme.color.PRIMARY};
  }

  &:active {
    transform: scale(0.95);
  }
`;

interface Props {
  label: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

const SmallButton: React.FC<Props> = ({ label, onClick, style }: Props) => (
  <StyledSmallButton onClick={onClick} style={style}>
    {label}
  </StyledSmallButton>
);
export default SmallButton;
