import React, { useState, useContext } from 'react';
import {
  Modal,
  TextCentered,
  ButtonGroup,
  Button,
  TitlePrimary,
  FormControl,
  FormLabel,
  TextField,
  Spacing,
  FileField,
  Preview,
  CheckboxRound,
} from '../atoms';
import {
  useControlledComponent,
  useControlledFileInput,
} from '../../lib/hooks';
import { postFormFetch, PostFormType } from '../../actions';
import { NoticeContext, PersonsContext } from '../../contexts';
import { useParams } from 'react-router-dom';
import { getUndonePersonCreateApiPath } from '../../constants/path';
import { createNotice } from '../../repositories/notice';

type Props = {
  show: boolean;
  onClose: () => void;
};

const ModalPersonCreate: React.FC<Props> = ({ show, onClose }: Props) => {
  // 定数の定義
  const { agencyId } = useParams<{ agencyId: string }>();
  // ローカルステートの利用
  const [isLoading, setIsLoading] = useState(false);
  // コンテクストの利用
  const { dispatchPersons } = useContext(PersonsContext);
  const { setNotices } = useContext(NoticeContext);

  // ローカルステートの利用
  const personName = useControlledComponent('');
  const personKana = useControlledComponent('');
  const personGender = useControlledComponent('');
  const { pdfData, onChange, currentFile } = useControlledFileInput(null);

  const isFormInvalid =
    personName.hasEmptyError ||
    personKana.hasEmptyError ||
    personGender.hasEmptyError ||
    currentFile === null;

  // 関数の定義
  const onRegist = async () => {
    // もしグループ名が空だったら処理を中断
    if (isFormInvalid) {
      return;
    }

    const postData = new FormData();
    postData.append('agency_slug', agencyId);
    postData.append('name', personName.value);
    postData.append('kana', personKana.value);
    postData.append('gender', personGender.value);
    postData.append('composite', currentFile);

    // loading表示
    setIsLoading(true);

    const postFormData: PostFormType = {
      targetPath: getUndonePersonCreateApiPath(),
      formData: postData,
      json: false,
    };

    const result = await postFormFetch(postFormData)
      .then(data => {
        return data;
      })
      .catch(err => {
        alert(
          'お気に入りグループの追加に失敗しました。電波状況をお確かめになってから再度お試しください。',
        );
        console.log(err);
        // loading解除
        setIsLoading(false);
      });

    // 通知を追加
    setNotices({
      type: 'ADD',
      payload: createNotice({ mode: result.status, text: result.message }),
    });

    // loading解除
    setIsLoading(false);

    if (result.status === 'success') {
      dispatchPersons({
        type: 'ADD_PERSON',
        person: result.detail,
      });
      // モーダル閉じる
      onClose();
      // フォームの初期化
      personName.setValue('');
      personKana.setValue('');
      personGender.setValue('');
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <TitlePrimary>モデルの新規登録</TitlePrimary>
      <TextCentered>
        モデルの名称を追加してください。
        <br />
        「ファイルを選択」をクリックし、pdfファイルをアップロードしてください。
      </TextCentered>
      <Spacing padding="40px 0">
        <FormControl>
          <FormLabel>■ モデル名</FormLabel>
          <TextField
            value={personName.value}
            onChange={e => personName.setValue(e.target.value)}
            placeholder="（例）山田花子"
            hasError={personName.hasEmptyError}
          />
        </FormControl>
        <FormControl>
          <FormLabel>■ カナ</FormLabel>
          <TextField
            value={personKana.value}
            onChange={e => personKana.setValue(e.target.value)}
            placeholder="（例）ヤマダハナコ"
            hasError={personKana.hasEmptyError}
          />
        </FormControl>
        <FormControl>
          <FormLabel>■ 性別</FormLabel>
          <div style={{ display: 'flex' }}>
            <CheckboxRound
              name={'gender[]'}
              value={'man'}
              checked={personGender.value === 'man'}
              onChange={e => personGender.setValue(e.target.value)}
              label="男性"
            />
            <CheckboxRound
              name={'gender[]'}
              value={'woman'}
              checked={personGender.value === 'woman'}
              onChange={e => personGender.setValue(e.target.value)}
              label="女性"
            />
          </div>
          {personGender.hasEmptyError ? (
            <span
              style={{ margin: '8px auto', fontSize: '12px', color: 'red' }}
            >
              入力が必須です。
            </span>
          ) : null}
        </FormControl>
        <FormControl>
          <FormLabel>■ ファイル添付</FormLabel>
          <FileField
            type="file"
            accept="application/pdf"
            onChange={e => onChange(e)}
            hintText="※PDFファイルのみアップロード可能"
            hasError={pdfData === null || pdfData === ''}
          />
        </FormControl>
        {pdfData && <Preview src={pdfData} />}
      </Spacing>
      <ButtonGroup>
        <Button label="キャンセル" ghost primary onClick={onClose} />
        <Button
          primary
          label="この内容で登録する"
          onClick={() => onRegist()}
          disabled={isFormInvalid}
          isLoading={isLoading}
        />
      </ButtonGroup>
    </Modal>
  );
};

export default ModalPersonCreate;
