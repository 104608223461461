import React, { ReactNode } from 'react';
import { PageOutline, Loading, PageFooter, Banner } from '../molecules';
import { ButtonGroup, Button, PaginationWrap, PaginationItem } from '../atoms';
import { getJsonData } from '../../actions';
import {
  getChoicesListApiPath,
  getPersonChoiceCreatePath,
  getPersonChoiceListPath,
} from '../../constants/path';
import { FetchedPageHeading } from '../organisms';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Choice } from '../../entities/choice';
import { Shooting } from '../../entities/shooting';
import { Proposal } from '../../entities/proposal';
import { CommonLayout } from '../templates';

export interface Proposals {
  proposals: [
    {
      proposal: Proposal;
      shooting: Partial<Shooting>;
      choice: Partial<Choice>;
    },
  ];
  page: number;
}

const PersonChoiceList: React.FC = () => {
  // 定数の定義
  const { agencyId, proposalId } = useParams<{
    agencyId: string;
    proposalId: string;
  }>();
  const navigate = useNavigate();
  const paramQuery = new URLSearchParams(useLocation().search);
  const currentPage = paramQuery.get('page')
    ? Number(paramQuery.get('page'))
    : 1;
  const pageTitle = `過去の案件一覧 - ${currentPage}ページ目`;

  // ローカルステートの使用
  const [isLoading, setIsLading] = React.useState(true);
  const [jsonData, setJsonData] = React.useState<Proposals>();
  const [pageCount, setPageCount] = React.useState(0);

  // 関数の定義
  const LinkToPersonChoice = proposalId => {
    const linkPath = getPersonChoiceCreatePath({
      agencyId: agencyId,
      proposalId: proposalId,
    });
    navigate(linkPath);
  };

  const LinkToListPage = index => {
    setIsLading(true);
    navigate({
      pathname: getPersonChoiceListPath({
        agencyId: agencyId,
        proposalId: proposalId,
      }),
      search: `?page=${index}`,
    });
  };

  const LinkToBack = () => {
    navigate(
      getPersonChoiceCreatePath({
        agencyId: agencyId,
        proposalId: proposalId,
      }),
      {
        state: {
          detailModalDisable: true,
        },
      },
    );
  };

  const GeneratePaging: React.FC = () => {
    const pagingItems: ReactNode[] = [];
    if (pageCount > 0) {
      for (let index = 1; index <= pageCount; index++) {
        pagingItems.push(
          <PaginationItem
            key={index.toString()}
            label={index.toString()}
            isCurrent={index === currentPage}
            onClick={() => LinkToListPage(index)}
          />,
        );
      }
      return <>{pagingItems}</>;
    }
    return null;
  };

  /*
   * useEffectは、第一引数にcallbackを入れて、第二引数に依存する値の配列を入れる
   * 第二引数に空の配列を入れることで、初回レンダー時にのみ発動する。
   */
  React.useEffect(() => {
    const targetPath = getChoicesListApiPath({
      agencyId: agencyId,
      page: currentPage,
    });
    getJsonData(targetPath)
      .then(data => {
        setJsonData(data);
        setIsLading(false);
        document.title = pageTitle;
        setPageCount(data.page);
      })
      .catch(error => {
        console.log(error);
      });
  }, [currentPage]);

  if (isLoading === true) {
    return <Loading />;
  } else {
    return (
      <CommonLayout>
        <FetchedPageHeading lead={pageTitle} />

        {jsonData.proposals.map(item => (
          <PageOutline
            key={item.proposal.id}
            proposal={item.proposal}
            choice={item.choice}
            shooting={item.shooting}
            onClick={() => LinkToPersonChoice(item.proposal.id)}
          />
        ))}

        <PageFooter>
          <PaginationWrap>
            <GeneratePaging />
          </PaginationWrap>
          <ButtonGroup>
            <Button label="モデル選択画面に戻る" onClick={LinkToBack} />
          </ButtonGroup>
        </PageFooter>

        {/* 広告エリア */}
        <Banner />
      </CommonLayout>
    );
  }
};

export default PersonChoiceList;
