import React from 'react';
import { Notice, NoticeContext } from '../entities/notice';

export const initialNoticeState = (): Notice[] => [];

export const Context = React.createContext<NoticeContext>({
  notices: initialNoticeState(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNotices: () => {},
});
