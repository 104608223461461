import React from 'react';
import styled from 'styled-components';

const StyledIconButtonGroup = styled.div`
  display: flex;

  > * {
    position: relative;
    margin: 0 15px;

    &:not(:first-child) {
      &::after {
        position: absolute;
        top: 0;
        left: -15px;
        width: 1px;
        height: 100%;
        content: '';
        background-color: ${props => props.theme.color.BLACK_LIGHT};
      }
    }
  }
`;

interface Props {
  children: React.ReactNode;
}

const IconButtonGroup: React.FC<Props> = ({ children }: Props) => (
  <StyledIconButtonGroup>{children}</StyledIconButtonGroup>
);

export default IconButtonGroup;
