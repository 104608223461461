import React from 'react';
import styled from 'styled-components';

const StyledPageContent = styled.div`
  padding: 30px 0;
`;

interface Props {
  children: React.ReactNode;
}

const PageContent: React.FC<Props> = ({ children }: Props) => (
  <StyledPageContent>{children}</StyledPageContent>
);

export default PageContent;
