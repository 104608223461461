import React from 'react';
import styled from 'styled-components';

const StyledTag = styled.span`
  padding: 0.25em 0.8em 0.2em;
  margin: 0 10px 0 0;
  font-size: 12px;
  font-weight: bold;
  color: white;
  white-space: nowrap;
  border-radius: 1em;
`;

const DefaultTag = styled(StyledTag)`
  background-color: ${props => props.theme.color.GRAY_LIGHT};
`;

const ActiveTag = styled(StyledTag)`
  background-color: ${props => props.theme.color.PRIMARY};
`;

type TagMode = 'default' | 'active';
type Props = {
  children: string;
  mode?: TagMode;
};

const Tag: React.FC<Props> = ({ children, mode = 'default' }: Props) => {
  let tag;
  if (mode === 'default') {
    tag = <DefaultTag>{children}</DefaultTag>;
  } else if (mode === 'active') {
    tag = <ActiveTag>{children}</ActiveTag>;
  } else {
    tag = null;
  }
  return tag;
};
export default Tag;
