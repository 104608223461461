import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Banner01 from '../../images/banner01.jpg';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 0 150px;
`;

const BannerTitle = styled.p`
  font-size: 13px;
  line-height: 1;
  margin: 0 0 1em;
`;

const BannerLink = styled.a`
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 1px 1px 4px rgba(4, 0, 0, 0.3);
  transition-property: opacity, transform, box-shadow;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    opacity: 0.9;
    box-shadow: none;
    transform: translateY(3px);
  }
`;

const BannerImg = styled.img`
  vertical-align: middle;
  width: 100%;
  height: auto;
`;

const Banner: FunctionComponent = () => {
  const bookUrl = 'https://modelbook.pmdesignoffice.com/';

  return (
    <Wrapper>
      <BannerTitle>PR</BannerTitle>
      <BannerLink href={bookUrl} target="_blank" rel="noopener noreferrer">
        <BannerImg src={Banner01} />
      </BannerLink>
    </Wrapper>
  );
};
export default Banner;
