import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../../constants/media';

const StyledCheckboxInput = styled.input`
  display: none;

  & + span {
    cursor: pointer;
    display: inline-block;
    width: 22px;
    height: 22px;
    background-color: white;
    border: 2px solid ${props => props.theme.color.PRIMARY};
    border-radius: 2px;
    transition: background-color 0.4s cubic-bezier(0.19, 1, 0.22, 1);

    ${mobile`
      width: 33px;
      height: 33px;
    `}
  }

  & + span:hover {
    background-color: ${props => props.theme.color.PRIMARY_LIGHT};
  }

  &:checked + span {
    position: relative;
    background-color: ${props => props.theme.color.PRIMARY};
  }

  &:checked + span::after {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
    color: white;
    content: '';
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%23ffffff" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" /></svg>')
      no-repeat center center;

    ${mobile`
      width: 30px;
      height: 30px;
    `}
  }
`;

interface Props {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string;
}

const Checkbox: React.FC<Props> = ({
  checked,
  onChange,
  name,
  value,
}: Props) => (
  <label>
    <StyledCheckboxInput
      type="checkbox"
      checked={checked}
      onChange={onChange}
      name={name}
      value={value}
    />
    <span></span>
  </label>
);
export default Checkbox;
