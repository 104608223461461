import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, CloseIcon } from '../atoms';
import { UiContext } from '../../contexts';
import {
  getPersonGroupCreatePath,
  getPersonChoiceListPath,
} from '../../constants/path';

const fadeInFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
  }
`;

const StyledDrawer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  display: block;
  width: 100%;
  padding: 170px 20px;
  text-align: right;
  background-color: ${props => props.theme.color.BLACK_LIGHT};
  animation: ${fadeInFromRight} 0.3s ease-in-out;
`;

const StyledDrawerItem = styled.div`
  position: relative;
  display: block;
  margin: 0 0 3em;
`;

const StyledDrawerLink = styled.a`
  position: relative;
  display: inline-block;
  color: white;
  cursor: pointer;
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    opacity: 0.5;

    &::after {
      width: 0;
    }
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: -8px;
    width: 100%;
    height: 1px;
    content: '';
    background-color: ${props => props.theme.color.GRAY_LIGHT};
    transition: width 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
`;

const DrawerClose = styled(CloseIcon)`
  position: absolute;
  top: -100px;
  right: 0;
  cursor: pointer;
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    transform: rotate(180deg);
  }
`;

const Drawer: React.FC = () => {
  // 定数の定義
  const { agencyId, proposalId } = useParams<{
    agencyId: string;
    proposalId: string;
  }>();
  const personChoiceListPath = getPersonChoiceListPath({
    agencyId: agencyId,
    proposalId: proposalId,
  });
  const personGroupCreatePath = getPersonGroupCreatePath({
    agencyId: agencyId,
    proposalId: proposalId,
  });
  const navigate = useNavigate();

  // コンテクストの利用
  const { uiSettings, dispatchUiSettings } = useContext(UiContext);

  const handleClose = () => {
    dispatchUiSettings({ type: 'TOGGLE_DRAWER' });
  };

  const handleToProposalList = () => {
    handleClose();
    navigate(personChoiceListPath);
  };

  const handleToModalPersonCreate = () => {
    handleClose();
    dispatchUiSettings({ type: 'TOGGLE_MODAL_PERSON_CREATE' });
  };

  const handleToModalPersonGroupCreate = () => {
    handleClose();
    navigate(personGroupCreatePath);
  };

  return uiSettings.drawerOpen ? (
    <StyledDrawer>
      <Container>
        <DrawerClose onClick={handleClose} />
        <StyledDrawerItem>
          <StyledDrawerLink onClick={handleToProposalList}>
            過去の案件一覧
          </StyledDrawerLink>
        </StyledDrawerItem>
        <StyledDrawerItem>
          <StyledDrawerLink onClick={handleToModalPersonCreate}>
            モデルの新規登録
          </StyledDrawerLink>
        </StyledDrawerItem>
        <StyledDrawerItem>
          <StyledDrawerLink onClick={handleToModalPersonGroupCreate}>
            お気に入りグループの新規登録
          </StyledDrawerLink>
        </StyledDrawerItem>
      </Container>
    </StyledDrawer>
  ) : null;
};

export default Drawer;
