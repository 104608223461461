import styled from 'styled-components';

export const TitlePrimary = styled.h2`
  margin: 0 0 1.5em;
  font-size: 18px;
  font-weight: bold;
  line-height: 31px;
  color: ${(props) => props.theme.color.PRIMARY};
  letter-spacing: ${120 / 1000}em;
`;
