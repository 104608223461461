import React from 'react';
import { Container, Spinner } from '../atoms';
import { PageHeading } from '../molecules';

const PageTitle = 'モデル等キャスティング取引規約';
const PageContent = React.lazy(() => import('../organisms/TermsContent'));

const Terms: React.FC = () => {
  document.title = PageTitle;
  return (
    <Container>
      <PageHeading title={PageTitle} subtitle="promodelstudio.com" />
      <React.Suspense fallback={<Spinner />}>
        <PageContent />
      </React.Suspense>
    </Container>
  );
};
export default Terms;
