import React from 'react';
import { UiContext } from '../../contexts';
import { Container, Hamburger } from '../atoms';
import { Drawer } from '../molecules';
import { ModalPersonCreate } from '../organisms';

interface Props {
  children: React.ReactNode;
}
const CommonLayout: React.FC<Props> = ({ children }) => {
  const { uiSettings, dispatchUiSettings } = React.useContext(UiContext);
  return (
    <Container>
      <Hamburger />
      <Drawer />
      {children}
      <ModalPersonCreate
        show={uiSettings.showModal.personCreate}
        onClose={() =>
          dispatchUiSettings({ type: 'TOGGLE_MODAL_PERSON_CREATE' })
        }
      />
    </Container>
  );
};
export default CommonLayout;
