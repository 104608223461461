import React from 'react';

const useControlledMultipleCheck = (
  initialObj: string[],
): {
  items: string[];
  hasItem: (item: string) => boolean;
  toggleItem: (item: string) => void;
  reset: () => void;
} => {
  const [items, setItems] = React.useState(initialObj);

  const hasItem = (item: string) => {
    return items.includes(item);
  };
  const toggleItem = (item: string) => {
    const isExist = hasItem(item);
    if (isExist) {
      setItems(items.filter(target => target !== item));
    } else {
      setItems([...items, item]);
    }
  };
  const reset = () => setItems([]);

  return {
    items,
    hasItem,
    toggleItem,
    reset,
  };
};
export default useControlledMultipleCheck;
