import { Person, PersonAction } from '../entities/person';

export const PersonsReducer = (
  state: Person[],
  action: PersonAction,
): Person[] => {
  switch (action.type) {
    case 'SET':
      return [...action.payload];

    case 'SORT_DSC_BY_KANA':
      return state.sort(sortDscByKana);

    case 'SORT_ASC_BY_KANA':
      return state.sort(sortAscByKana);

    case 'SORT_DSC_BY_ALPHA':
      return state.sort(sortDscByAlpha);

    case 'SORT_ASC_BY_ALPHA':
      return state.sort(sortAscByAlpha);

    case 'SORT_BY_CHECKED':
      return state.sort(sortDscByKana).sort(sortHasChecked);

    case 'TOGGLE_CHECKED_PERSONS':
      return togglePersonCheck(state, action.person);

    case 'TOGGLE_ALL_PERSONS_CHECK':
      return toggleAllPersonCheck(state, action.payload);

    case 'APPLY_PERSON_GROUP':
      return appliedGroupedPersons(state, action.payload);

    case 'APPLY_MULTIPLE_GROUPS':
      return appliedMultipleGroupedPersons(state, action.payload);

    case 'ADD_PERSON':
      return [...state, action.person];

    case 'UPDATE_PERSON':
      return updatedPersonsArray(state, action.payload);

    default:
      return state;
  }
};

const sortDscByKana = (a: Person, b: Person): number => {
  if (a.kana > b.kana) {
    return 1;
  }
  return -1;
};

const sortAscByKana = (a: Person, b: Person): number => {
  if (a.kana > b.kana) {
    return -1;
  }
  return 1;
};

const sortDscByAlpha = (a: Person, b: Person): number => {
  if (a.display_name > b.display_name) {
    return 1;
  }
  return -1;
};

const sortAscByAlpha = (a: Person, b: Person): number => {
  if (a.display_name > b.display_name) {
    return -1;
  }
  return 1;
};

const sortHasChecked = (a: Person, b: Person): number => {
  if (a.isChecked > b.isChecked) {
    return -1;
  }
  return 1;
};

const togglePersonCheck = (state: Person[], person: Person) => {
  const persons = state.map(currentPerson => {
    if (currentPerson.person_id === person.person_id) {
      currentPerson.isChecked = !currentPerson.isChecked;
    }
    return currentPerson;
  });
  return persons;
};

const toggleAllPersonCheck = (state: Person[], value: boolean) => {
  return state.map(item => {
    if (item.isVisible) {
      item.isChecked = !value;
    }
    return item;
  });
};

const appliedGroupedPersons = (state: Person[], personGroup: Person[]) => {
  const personIds = personGroup.map(person => person.person_id);
  state.forEach(person => {
    if (personIds.includes(person.person_id)) {
      person.isChecked = true;
    } else {
      person.isChecked = false;
    }
  });
  return state;
};

const appliedMultipleGroupedPersons = (state: Person[], multipleGroups) => {
  // まずはcontext上のチェックを全て解除する
  state.forEach(person => (person.isChecked = false));
  // グループをループさせ、それぞれグループ内に含まれるpersonIdを取得してstateに反映
  multipleGroups.forEach(group => {
    const personIds = group.persons.map(person => person.person_id);
    state.forEach(person => {
      if (personIds.includes(person.person_id)) {
        person.isChecked = true;
      }
    });
  });
  return state;
};

const updatedPersonsArray = (state: Person[], person: Person) => {
  state.forEach(target => {
    // 現状コンポジットの画像のURLだけ変更可能
    if (target.person_id === person.person_id) {
      target.composite_path = person.composite_path;
    }
  });
  return state;
};
