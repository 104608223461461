import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../atoms';

const LoadingWrap = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
`;

const Loading: React.FC = () => (
  <LoadingWrap>
    <Spinner />
  </LoadingWrap>
);

export default Loading;
