import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../constants/media';
import { Shooting } from '../../entities/shooting';

const ClosingTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 630px;
  width: 100%;
  padding: 1.2em;
  border: 1.7px solid ${props => props.theme.color.PRIMARY};
  margin: 2em 0;
`;

const ClosingTextInner = styled.div`
  font-weight: bold;
  text-align: center;
`;

const ClosingTextSubTitle = styled.span`
  ${mobile`
  display: block;
  line-height: 1.2;
  `}
`;

const Strong = styled.strong`
  font-size: 28px;
  font-weight: normal;
`;

interface Props {
  shooting: Shooting;
}

const ClosingText: React.FC<Props> = ({ shooting }: Props) => {
  const isExists =
    'expiry_date_array' in shooting && shooting.expiry_date_array.length > 0;

  if (isExists) {
    return (
      <ClosingTextWrap>
        <ClosingTextInner>
          <ClosingTextSubTitle>［ モデル選定締め切り ］</ClosingTextSubTitle>
          <Strong> {shooting.expiry_date_array[0]} </Strong>年
          <Strong> {shooting.expiry_date_array[1]} </Strong>月
          <Strong> {shooting.expiry_date_array[2]} </Strong>日 (
          {shooting.expiry_date_array[3]})
        </ClosingTextInner>
      </ClosingTextWrap>
    );
  }
  return null;
};
export default ClosingText;
