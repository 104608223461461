/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export async function getJsonData(targetPath = '/') {
  const baseUrl = process.env.REACT_APP_API_URL;
  const TargetUrl = baseUrl + targetPath;
  const fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
  };
  const response = await fetch(TargetUrl, fetchOptions as Partial<RequestInit>);
  const data = await response.json();
  return data;
}

export type PostFormType = {
  targetPath: string;
  formData: any;
  json?: boolean;
};
export async function postFormFetch({
  targetPath = '/',
  formData,
  json = true,
}: PostFormType) {
  const baseUrl = process.env.REACT_APP_API_URL;
  const TargetUrl = baseUrl + targetPath;
  const body = json ? JSON.stringify(formData) : formData;
  const fetchOptions: Partial<RequestInit> = {
    method: 'POST',
    body: body,
  };
  if (json) {
    fetchOptions.headers = { 'Content-Type': 'application/json' };
  }
  const response = await fetch(TargetUrl, fetchOptions);
  const data = await response.json();
  return data;
}

export const scrollToTop = (): void => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
