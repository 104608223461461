import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  SmallButton,
  IconButton,
  IconButtonGroup,
  Selectbox,
  Spacing,
  Checkbox,
} from '../atoms';
import { UiContext, PersonsContext } from '../../contexts';
import { mobile, tablet } from '../../constants/media';
import { AlphabetList, KanaList, orderOptions } from '../../constants/options';
import { PersonGender, PersonOrder } from '../../entities/ui';
import { ReactComponent as GridIcon } from '../../images/grid-icon.svg';
import { ReactComponent as ListIcon } from '../../images/list-icon.svg';

const StyledSortArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1100px;
  margin: 35px auto 0;

  ${tablet`
    width: 100%;
  `}

  ${mobile`
    width: 100%;
  `}
`;

const SortAreaRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;

  ${mobile`
    flex-direction: column;
    align-items: center;
  `}
`;

const SortAreaGrid = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 16px 24px;

  ${mobile`
    margin: 0 0 16px;
  `}
`;

const TextLabel = styled.p`
  display: inline-block;
  margin: 0 1em 0;
  font-size: 11px;
`;

const TextList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const StyledTextListItem = styled.button<{ isActive?: boolean }>`
  padding: 0 1em;
  margin: 1em 0;
  font-size: 11.5px;
  cursor: pointer;
  border-right: 1px solid ${props => props.theme.color.BLACK};
  transition-property: transform, font-weight, color, text-decoration;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  &:last-child {
    border-right: none;
  }

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    font-weight: bold;
    color: ${props => props.theme.color.PRIMARY};
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    color: ${theme.color.PRIMARY};
    text-decoration: underline;
  `}
`;

interface Props {
  isBtnHidden?: boolean;
}

const SortArea: React.FC<Props> = ({ isBtnHidden = false }: Props) => {
  // コンテクストの利用
  const { uiSettings, dispatchUiSettings } = useContext(UiContext);
  const { dispatchPersons } = useContext(PersonsContext);

  // 関数の定義
  const handleSetFormatEnum = () => {
    dispatchUiSettings({ type: 'CHANGE_PERSONS_FORMAT' });
  };

  const handleSetOrderEnum = (order: PersonOrder) => {
    dispatchUiSettings({ type: 'CHANGE_ORDER', order: order });
    if (order === 'KANA_DSC') {
      dispatchPersons({ type: 'SORT_DSC_BY_KANA' });
    } else if (order === 'KANA_ASC') {
      dispatchPersons({ type: 'SORT_ASC_BY_KANA' });
    } else if (order === 'ALPHA_DSC') {
      dispatchPersons({ type: 'SORT_DSC_BY_ALPHA' });
    } else if (order === 'ALPHA_ASC') {
      dispatchPersons({ type: 'SORT_ASC_BY_ALPHA' });
    } else if (order === 'iS_CHECKED') {
      dispatchPersons({ type: 'SORT_BY_CHECKED' });
    }
  };

  const handleSetGenderEnum = (value: PersonGender) => {
    dispatchUiSettings({ type: 'SORT_GENDER', gender: value });
  };

  const handleSetInitials = (value: string) => {
    dispatchUiSettings({
      type: 'SORT_INITIALS',
      initial: uiSettings.sortInitials === value ? '' : value,
    });
  };

  const handleSetCheckAll = () => {
    dispatchUiSettings({ type: 'TOGGLE_CHECKED_ALL' });
    dispatchPersons({
      type: 'TOGGLE_ALL_PERSONS_CHECK',
      payload: uiSettings.checksAll,
    });
  };

  return (
    <StyledSortArea>
      <SortAreaRow>
        <TextList>
          {KanaList.map(value => (
            <StyledTextListItem
              key={value}
              isActive={uiSettings.sortInitials === value}
              onClick={() => handleSetInitials(value)}
            >
              {value}
            </StyledTextListItem>
          ))}
        </TextList>
      </SortAreaRow>
      <Spacing margin="0 0 16px">
        <SortAreaRow>
          <TextList>
            {AlphabetList.map(value => (
              <StyledTextListItem
                key={value}
                isActive={uiSettings.sortInitials === value}
                onClick={() => handleSetInitials(value)}
              >
                {value}
              </StyledTextListItem>
            ))}
          </TextList>
        </SortAreaRow>
      </Spacing>
      <SortAreaRow>
        <SortAreaGrid>
          <TextLabel>全チェック：</TextLabel>
          <Checkbox
            checked={uiSettings.checksAll}
            onChange={handleSetCheckAll}
          />
        </SortAreaGrid>
        {!isBtnHidden && (
          <SortAreaGrid>
            <SmallButton
              label="お気に入りグループの選択"
              style={{ margin: '0 20px 0 0' }}
              onClick={() =>
                dispatchUiSettings({ type: 'TOGGLE_MODAL_GROUP_APPLY' })
              }
            />
            <SmallButton
              label="モデル新規登録"
              onClick={() =>
                dispatchUiSettings({ type: 'TOGGLE_MODAL_PERSON_CREATE' })
              }
            />
          </SortAreaGrid>
        )}
        <SortAreaGrid>
          <TextLabel>性別：</TextLabel>
          <Checkbox
            value="woman"
            checked={uiSettings.sortGender.woman}
            onChange={e => {
              const gender = e.target.value;
              handleSetGenderEnum(gender as PersonGender);
            }}
          />
          <TextLabel>女性</TextLabel>
          <Checkbox
            value="man"
            checked={uiSettings.sortGender.man}
            onChange={e => {
              const gender = e.target.value;
              handleSetGenderEnum(gender as PersonGender);
            }}
          />
          <TextLabel>男性</TextLabel>
        </SortAreaGrid>
        <SortAreaGrid>
          <TextLabel>並び替え：</TextLabel>
          <Selectbox
            options={orderOptions}
            value={uiSettings.personListOrder}
            onChange={e => {
              const order = e.target.value;
              handleSetOrderEnum(order as PersonOrder);
            }}
          />
        </SortAreaGrid>
        <SortAreaGrid>
          <IconButtonGroup>
            <IconButton
              isActive={uiSettings.personListFormat === 'GRID'}
              onClick={handleSetFormatEnum}
            >
              <GridIcon />
            </IconButton>
            <IconButton
              isActive={uiSettings.personListFormat === 'LIST'}
              onClick={handleSetFormatEnum}
            >
              <ListIcon />
            </IconButton>
          </IconButtonGroup>
        </SortAreaGrid>
      </SortAreaRow>
    </StyledSortArea>
  );
};
export default SortArea;
