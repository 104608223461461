import React from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  padding?: string;
  margin?: string;
}

const StyledSpacing = styled.div<Props>`
  ${({ padding }) =>
    padding &&
    `
    padding: ${padding};
  `}
  ${({ margin }) =>
    margin &&
    `
    margin: ${margin};
  `}
`;

const Spacing: React.FC<Props> = ({ children, padding, margin }: Props) => (
  <StyledSpacing padding={padding} margin={margin}>
    {children}
  </StyledSpacing>
);
export default Spacing;
