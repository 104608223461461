import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Container, TextCentered, Button, Spacing, Hamburger } from '../atoms';
import { mobile, tablet } from '../../constants/media';

const slideInFromTop = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const StyledFixedHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99; /* drawerが100なのでそれよりも下にする */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 108px;
  color: ${props => props.theme.color.BLACK};
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.color.GRAY_LIGHT};
  animation: ${slideInFromTop} 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const FixedHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${tablet`
  padding: 0 45px 0 0;
  `}

  ${mobile`
  padding: 0 35px 0 0;
  `}
`;

const TextArea = styled.div`
  ${mobile`
  display: none;
  `}
`;

interface Props {
  count: number;
  onClick: () => void;
}

const FixedHeader: React.FC<Props> = ({ count, onClick }: Props) => {
  // ローカルステートの利用
  const [isOver, setOver] = useState(false);

  // スクロール位置によってステートを更新する関数
  const onScroll = () => {
    const offset =
      window.pageYOffset ||
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;

    offset >= 600 ? setOver(true) : setOver(false);
  };

  // 初回読み込み時にイベントの登録
  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      {isOver && (
        <StyledFixedHeader>
          <Container>
            <FixedHeaderContent>
              <TextArea>
                <TextCentered>
                  モデル {count} 名が選択されています。
                  よろしければ、「確認画面へ進む」ボタンを押してください。
                </TextCentered>
              </TextArea>
              <Spacing margin="0 0 0 30px">
                <Button
                  primary
                  size="small"
                  label="確認画面へ進む"
                  onClick={onClick}
                  disabled={!(count > 0)}
                />
              </Spacing>
            </FixedHeaderContent>
            <Hamburger style={{ top: '0', bottom: '0' }} />
          </Container>
        </StyledFixedHeader>
      )}
    </>
  );
};
export default FixedHeader;
