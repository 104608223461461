export const theme = {
  color: {
    PRIMARY: '#63bec6',
    PRIMARY_LIGHT: '#f3f9fa',
    SECONDARY: '#db5443',
    SECONDARY_LIGHT: 'rgba(231, 31, 25, .7)',
    DANGER: '#e8380d',
    BLACK: '#231815',
    BLACK_LIGHT: 'rgba(35,24,21,.7)',
    GRAY: '#ddd',
    GRAY_LIGHT: '#e6e6e5',
  },
  size: {
    container: '1040px',
    padding: '20px',
  },
};
