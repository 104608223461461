import { createGlobalStyle } from 'styled-components';
import ResetStyle from './Reset';

const GlobalStyle = createGlobalStyle`
  ${ResetStyle}
  /*
  * web font
  */
  @font-face {
    font-family: 'AvenirNext-Regular';
    src: url('/fonts/AvenirNext-Regular.ttf') format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'AvenirNext-DemiBold';
    src: url('/fonts/AvenirNext-DemiBold.ttf') format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'URW-DIN-SemiCond-W05-Thin';
    src: url('/fonts/0d056127-d37b-4fa1-9be0-e124e459d3f7.ttf') format('truetype');
    font-weight: 600;
    font-display: block;
  }

  body,
  input,
  textarea,
  button,
  select {
    font-family: 'Avenir Next', 'AvenirNext-Regular', 'Yu Gothic Medium', '游ゴシック体', YuGothic, Hiragino Sans, 'ヒラギノ角ゴシック', Hiragino Kaku Gothic ProN, 'ヒラギノ角ゴ ProN W3', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: ${props => props.theme.color.BLACK};
    letter-spacing: .075em;
    background: white;
    font-feature-settings: 'palt';
    text-size-adjust: 100%;
  }

  a {
    color: ${props => props.theme.color.BLACK};
    text-decoration: none;
  }

  @keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
  }

  @keyframes fadeInUp {
    0% {
      opacity : 0;
      visibility: hidden;
      transform: translateY(30px);
    }

    100% {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  /* fade image in after load */
  .lazyload,
  .lazyloading {
    opacity: 0;
  }

  .lazyloaded {
    opacity: 1;
    transition: opacity .4s cubic-bezier(.25, 1, .22, 1);
  }
`;
export default GlobalStyle;
