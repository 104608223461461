import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../atoms';

const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  width: 100vw;
  height: 100%;
  margin: auto;
  overflow: hidden;
  background-color: ${props => props.theme.color.BLACK};
  animation: fadeIn 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

const ModalInner = styled.div`
  position: relative;
  height: 100%;
  padding: 20px;
  overflow: auto;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 815px;
  max-width: 100%;
  height: auto;
  padding: 32px 40px;
  margin: auto;
  overflow: initial;
  background-color: white;
  border-radius: 15px;
`;

const CloseIconWrap = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;

  &::before,
  &::after {
    width: 1px;
    background-color: ${props => props.theme.color.BLACK};
  }
`;

interface Props {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<Props> = ({ show, onClose, children }: Props) => {
  return (
    <>
      {show ? (
        <ModalWrap>
          <ModalInner>
            <ModalContent>
              {onClose && (
                <CloseIconWrap>
                  <StyledCloseIcon onClick={onClose} />
                </CloseIconWrap>
              )}
              {children}
            </ModalContent>
          </ModalInner>
        </ModalWrap>
      ) : null}
    </>
  );
};

export default Modal;
