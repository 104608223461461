import { checkCharType } from '../lib/utility';
import { UiSettings } from '../entities/ui';
import { Person } from '../entities/person';

export const isMatchInitials = (initials: string, person: Person): boolean => {
  /*
   * イニシャルとPersonを比較してイニシャルが一致してたらTrueを返す。
   * ローマ字とカタカナに対応してます。
   */
  if (checkCharType(initials, 'katakana') === true) {
    if (!person.kana) {
      return false;
    }

    const i = person.kana.slice(0, 1);

    if (
      (i === 'ア' || i === 'イ' || i === 'ウ' || i === 'エ' || i === 'オ') &&
      initials === 'ア'
    ) {
      return true;
    }
    if (
      (i === 'カ' || i === 'キ' || i === 'ク' || i === 'ケ' || i === 'コ') &&
      initials === 'カ'
    ) {
      return true;
    }
    if (
      (i === 'ガ' || i === 'ギ' || i === 'グ' || i === 'ゲ' || i === 'ゴ') &&
      initials === 'カ'
    ) {
      return true;
    }
    if (
      (i === 'サ' || i === 'シ' || i === 'ス' || i === 'セ' || i === 'ソ') &&
      initials === 'サ'
    ) {
      return true;
    }
    if (
      (i === 'ザ' || i === 'ジ' || i === 'ズ' || i === 'ゼ' || i === 'ゾ') &&
      initials === 'サ'
    ) {
      return true;
    }
    if (
      (i === 'タ' || i === 'チ' || i === 'ツ' || i === 'テ' || i === 'ト') &&
      initials === 'タ'
    ) {
      return true;
    }
    if (
      (i === 'ダ' || i === 'ヂ' || i === 'ヅ' || i === 'デ' || i === 'ド') &&
      initials === 'タ'
    ) {
      return true;
    }
    if (
      (i === 'ナ' || i === 'ニ' || i === 'ヌ' || i === 'ネ' || i === 'ノ') &&
      initials === 'ナ'
    ) {
      return true;
    }
    if (
      (i === 'ハ' || i === 'ヒ' || i === 'フ' || i === 'へ' || i === 'ホ') &&
      initials === 'ハ'
    ) {
      return true;
    }
    if (
      (i === 'バ' || i === 'ビ' || i === 'ブ' || i === 'ベ' || i === 'ボ') &&
      initials === 'ハ'
    ) {
      return true;
    }
    if (
      (i === 'パ' || i === 'ピ' || i === 'プ' || i === 'ペ' || i === 'ポ') &&
      initials === 'ハ'
    ) {
      return true;
    }
    if (
      (i === 'マ' || i === 'ミ' || i === 'ム' || i === 'メ' || i === 'モ') &&
      initials === 'マ'
    ) {
      return true;
    }
    if ((i === 'ヤ' || i === 'ユ' || i === 'ヨ') && initials === 'ヤ') {
      return true;
    }
    if (
      (i === 'ラ' || i === 'リ' || i === 'ル' || i === 'レ' || i === 'ロ') &&
      initials === 'ラ'
    ) {
      return true;
    }
    if ((i === 'ワ' || i === 'ヲ' || i === 'ン') && initials === 'ワ') {
      return true;
    }
  }

  if (checkCharType(initials, 'alphabetic') === true) {
    if (!person.display_name) {
      return false;
    }
    const j = person.display_name.slice(0, 1);

    if (j.toUpperCase() === initials) {
      return true;
    }
  }

  return false;
};

const isMatchSortCondition = (person: Person, uiSettings: UiSettings) => {
  // 確認モードと選択完了画面ではチェックの有無だけで判断
  if (
    uiSettings.choiceMode === 'COMPLETED' ||
    uiSettings.choiceMode === 'CONFIRM'
  ) {
    return !!person.isChecked;
  }

  // 該当の性別のチェックボックスが無しなら非表示
  if (uiSettings.sortGender[person.gender] === false) {
    return false;
  }

  // イニシャルでのソート検索がかかっており、且つイニシャルに該当しなければ非表示
  if (
    uiSettings.sortInitials &&
    !isMatchInitials(uiSettings.sortInitials, person)
  ) {
    return false;
  }

  return true;
};

export const filterPersonByConditions = (
  persons: Person[],
  uiSettings: UiSettings,
): Person[] => {
  if (persons.length) {
    persons.forEach(person => {
      if (isMatchSortCondition(person, uiSettings)) {
        person.isVisible = true;
      } else {
        person.isVisible = false;
      }
    });
  }
  return persons;
};
export default filterPersonByConditions;
