import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Banner,
  SortArea,
  PageFooter,
  PageContent,
  Loading,
  Persons,
} from '../../components/molecules';
import { TextCentered, Button, ButtonGroup } from '../../components/atoms';
import {
  ModalPersonGroupConfirm,
  FormCreateGroup,
  FetchedPageHeading,
} from '../organisms';
import {
  UiContext,
  PersonsContext,
  getCheckedPersonsCount,
} from '../../contexts';
import {
  getPersonChoiceCreatePath,
  getPersonGroupCreateApiPath,
  getPersonsListApiPath,
} from '../../constants/path';
import { useControlledComponent } from '../../lib/hooks';
import { getJsonData, scrollToTop } from '../../actions';
import { ChoiceMode } from '../../entities/ui';
import { CommonLayout } from '../templates';

const PersonGroupCreate: React.FC = () => {
  // カスタムフックの利用
  const { agencyId, proposalId } = useParams<{
    agencyId: string;
    proposalId: string;
  }>();
  const navigate = useNavigate();
  const groupName = useControlledComponent('');

  // コンテクストの利用
  const { uiSettings, dispatchUiSettings } = React.useContext(UiContext);
  const { personsState, dispatchPersons } = React.useContext(PersonsContext);

  // ローカルステートの利用
  const [isLoadingState, setLoadingState] = React.useState(true);

  document.title = 'お気に入りグループの新規登録';

  // 関数の定義
  const personChoiceCreatePath = getPersonChoiceCreatePath({
    agencyId: agencyId,
    proposalId: proposalId,
  });

  const toggleChoiceMode = React.useCallback(
    (mode: ChoiceMode): ChoiceMode => {
      return mode === 'CREATE' ? 'CONFIRM' : 'CREATE';
    },
    [uiSettings.choiceMode],
  );

  const toggleConfirm = () => {
    dispatchUiSettings({
      type: 'CHANGE_MODE',
      mode: toggleChoiceMode(uiSettings.choiceMode),
    });
  };

  const openConfirmModal = () => {
    if (groupName.hasEmptyError) {
      scrollToTop();
    } else {
      toggleConfirm();
    }
  };

  // 初回レンダリング時にUI設定を初期化
  React.useEffect(() => dispatchUiSettings({ type: 'RESET' }), []);

  // モデル配列データの取得
  React.useEffect(() => {
    const targetPath = getPersonsListApiPath({ agencyId: agencyId });
    getJsonData(targetPath)
      .then(data => {
        dispatchPersons({ type: 'SET', payload: data.persons });
        setLoadingState(false);
      })
      .catch(err => console.log(err));
  }, []);

  if (isLoadingState) {
    return <Loading />;
  } else {
    return (
      <CommonLayout>
        <FetchedPageHeading lead="お気に入りグループにモデルを追加する" />
        <FormCreateGroup
          initialValue={groupName.value}
          onChange={groupName.setValue}
          hasError={groupName.hasEmptyError}
        />
        <PageContent>
          <SortArea isBtnHidden={true} />
          <Persons data={personsState} uiSettings={uiSettings} />
        </PageContent>
        <PageFooter>
          <TextCentered>
            モデル {getCheckedPersonsCount(personsState)} 名が選択されています。
            <br />
            上記のモデルでよろしければ、「確認画面へ進む」ボタンを押してください。
          </TextCentered>
          <ButtonGroup>
            <Button
              label="モデル選択画面に戻る"
              onClick={() =>
                navigate(personChoiceCreatePath, {
                  state: {
                    detailModalDisable: true,
                  },
                })
              }
            />
            <Button
              primary
              label="確認画面へ進む"
              onClick={openConfirmModal}
              disabled={!getCheckedPersonsCount(personsState)}
            />
          </ButtonGroup>
        </PageFooter>

        {/* 広告エリア */}
        <Banner />

        {/* モーダル */}
        <ModalPersonGroupConfirm
          show={uiSettings.choiceMode === 'CONFIRM'}
          onClose={toggleConfirm}
          groupName={groupName.value}
          apiPath={getPersonGroupCreateApiPath()}
        />
      </CommonLayout>
    );
  }
};
export default PersonGroupCreate;
