import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../constants/media';
import { Shooting } from '../../entities/shooting';

const InformationWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 103px;
  padding: 0 3em;
  background-color: rgba(99, 190, 198, 0.8);
`;

const InformationText = styled.div`
  font-weight: bold;
  color: white;
  text-align: center;
`;

const InformationSubTitle = styled.span`
  ${mobile`
  display: block;
  line-height: 1.2;
  `}
`;

const Strong = styled.strong`
  font-size: 28px;
  font-weight: normal;
`;

const InformationButton = styled.div`
  position: absolute;
  top: 0;
  right: 2em;
  bottom: 0;
  width: 27px;
  height: 27px;
  margin: auto;
  cursor: pointer;

  &::before,
  &:after {
    position: absolute;
    left: 15px;
    width: 2px;
    height: 28px;
    content: ' ';
    background-color: white;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

interface Props {
  shooting: Shooting;
}

const Information: React.FC<Props> = ({ shooting }: Props) => {
  const [isVisible, setIsVisible] = React.useState(true);

  return (
    <>
      {isVisible &&
        'expiry_date_array' in shooting &&
        shooting.expiry_date_array.length > 0 && (
          <InformationWrap>
            <InformationText>
              <InformationSubTitle>
                ［ モデル選定締め切り ］
              </InformationSubTitle>
              <Strong> {shooting.expiry_date_array[0]} </Strong>年
              <Strong> {shooting.expiry_date_array[1]} </Strong>月
              <Strong> {shooting.expiry_date_array[2]} </Strong>日 (
              {shooting.expiry_date_array[3]})
            </InformationText>
            <InformationButton onClick={() => setIsVisible(false)} />
          </InformationWrap>
        )}
    </>
  );
};
export default Information;
