import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  SortArea,
  PageFooter,
  PageContent,
  Loading,
  Persons,
} from '../molecules';
import { TextCentered, Button, ButtonGroup } from '../atoms';
import {
  ModalPersonGroupConfirm,
  FormCreateGroup,
  FetchedPageHeading,
} from '../organisms';
import {
  UiContext,
  PersonsContext,
  getCheckedPersonsCount,
} from '../../contexts';
import {
  getPersonChoiceCreatePath,
  getPersonGroupDetailApiPath,
  getPersonGroupUpdateApiPath,
  getPersonsListApiPath,
} from '../../constants/path';
import { useControlledComponent } from '../../lib/hooks';
import {
  getJsonData,
  scrollToTop,
  postFormFetch,
  PostFormType,
} from '../../actions';
import { ChoiceMode } from '../../entities/ui';
import { CommonLayout } from '../templates';

const PersonGroupUpdate: React.FC = () => {
  // title設定
  document.title = 'お気に入りグループの編集';
  // 定数の定義
  const { agencyId, proposalId, personGroupId } = useParams<{
    agencyId: string;
    proposalId: string;
    personGroupId: string;
  }>();
  const navigate = useNavigate();
  const personChoiceCreatePath = getPersonChoiceCreatePath({
    agencyId: agencyId,
    proposalId: proposalId,
  });
  const groupName = useControlledComponent('');

  // コンテクストの利用
  const { uiSettings, dispatchUiSettings } = React.useContext(UiContext);
  const { personsState, dispatchPersons } = React.useContext(PersonsContext);

  // ローカルステートの利用
  const [isLoadingState, setLoadingState] = React.useState(true);

  const toggleChoiceMode = (mode: ChoiceMode): ChoiceMode => {
    return mode === 'CREATE' ? 'CONFIRM' : 'CREATE';
  };

  // 関数の定義
  const toggleConfirm = () => {
    dispatchUiSettings({
      type: 'CHANGE_MODE',
      mode: toggleChoiceMode(uiSettings.choiceMode),
    });
  };

  const openConfirmModal = React.useCallback(() => {
    if (groupName.hasEmptyError) {
      scrollToTop();
    } else {
      toggleConfirm();
    }
  }, [groupName]);

  // 初回レンダリング時にUI設定を初期化
  React.useEffect(() => dispatchUiSettings({ type: 'RESET' }), []);

  React.useEffect(() => {
    const targetPath = getPersonsListApiPath({ agencyId: agencyId });
    getJsonData(targetPath)
      .then(data => {
        dispatchPersons({ type: 'SET', payload: data.persons });

        const targetPath = getPersonGroupDetailApiPath({
          personGroupId: personGroupId,
        });
        const PostFormData: PostFormType = {
          targetPath: targetPath,
          formData: {},
        };
        postFormFetch(PostFormData)
          .then(data => {
            // お気に入りグループに属するpersonにチェックをつける
            dispatchPersons({
              type: 'APPLY_PERSON_GROUP',
              payload: data.detail.persons,
            });
            groupName.setValue(data.detail.name);
            // loadingをオフにする
            setLoadingState(false);
          })
          .catch(err => {
            alert(
              'データを取得できませんでした。電波状況をお確かめになってから再度お試しください。',
            );
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  if (isLoadingState) {
    return <Loading />;
  } else {
    return (
      <CommonLayout>
        <FetchedPageHeading lead="お気に入りグループの内容を変更する" />

        <FormCreateGroup
          initialValue={groupName.value}
          onChange={groupName.setValue}
          hasError={groupName.hasEmptyError}
        />

        <PageContent>
          <SortArea isBtnHidden={true} />
          <Persons data={personsState} uiSettings={uiSettings} />
        </PageContent>

        <PageFooter>
          <TextCentered>
            モデル {personsState.length} 名が選択されています。
            <br />
            上記のモデルでよろしければ、「確認画面へ進む」ボタンを押してください。
          </TextCentered>
          <ButtonGroup>
            <Button
              label="モデル選択画面に戻る"
              onClick={() =>
                navigate(personChoiceCreatePath, {
                  state: { detailModalDisable: true },
                })
              }
            />
            <Button
              primary
              label="確認画面へ進む"
              onClick={openConfirmModal}
              disabled={!getCheckedPersonsCount(personsState)}
            />
          </ButtonGroup>
        </PageFooter>

        {/* モーダル */}
        <ModalPersonGroupConfirm
          show={uiSettings.choiceMode === 'CONFIRM'}
          onClose={toggleConfirm}
          groupName={groupName.value}
          apiPath={getPersonGroupUpdateApiPath({
            personGroupId: personGroupId,
          })}
        />
      </CommonLayout>
    );
  }
};
export default PersonGroupUpdate;
