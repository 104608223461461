import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../../constants/media';

const StyledDataListText = styled.div`
  font-size: 13px;
  line-height: 2;

  ${mobile`
  text-align: center;
  `}
`;

interface Props {
  children: React.ReactNode;
}

export const DataListText: React.FC<Props> = ({ children }: Props) => (
  <StyledDataListText>{children}</StyledDataListText>
);

export default DataListText;
