import { PersonOrder } from '../entities/ui';

export const KanaList = [
  'ア',
  'カ',
  'サ',
  'タ',
  'ナ',
  'ハ',
  'マ',
  'ヤ',
  'ラ',
  'ワ',
];

export const AlphabetList = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const orderOptions: {
  key: PersonOrder;
  value: string;
}[] = [
  { key: 'KANA_DSC', value: 'カナ降順' },
  { key: 'KANA_ASC', value: 'カナ昇順' },
  { key: 'ALPHA_DSC', value: 'ローマ字降順' },
  { key: 'ALPHA_ASC', value: 'ローマ字昇順' },
  { key: 'iS_CHECKED', value: 'チェック済み優先表示' },
];
