import React from 'react';
import styled from 'styled-components';

const ContainerWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: ${props => props.theme.size.container};
  padding: 0 ${props => props.theme.size.padding};
  margin: 0 auto;
`;

interface Props {
  children: React.ReactNode;
}

const Container: React.FC<Props> = ({ children }: Props) => (
  <ContainerWrap>{children}</ContainerWrap>
);

export default Container;
